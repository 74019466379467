import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import NotFound from './components/Pages/NotFound';
import EventEnded from './components/Pages/EventEnded';
import VerifyAccount from './components/Widget/VerifyAccount';
import EventPage from "./components/Pages/EventPage";

class App extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    // console.log('getDerivedStateFromError')
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // console.log('componentDidCatch')
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ hasError: true });
    console.error(error, errorInfo);
  }

  render() {
    const { history, t } = this.props;

    return (
      <Router history={history}>
        <div className='app-wrapper'>
          <Header />
          <Routes>
            <Route exact path="/call/:linkId" element={<EventPage redirectOnEnd="/event-ended" />} />
            <Route exact path="/verify/:linkId" element={<EventPage redirectOnEnd="/event-ended" />} />
            <Route exact path="/eventreg/:linkId/*"
              element={<EventPage redirectOnEnd="/event-ended" redirectOnJoin="/live/:linkId/:event-title" />}
            />
            <Route exact path="/live/:linkId/*"
              element={<EventPage redirectOnEnd="/event-ended" redirectToLogin="/eventrg/:linkId" autoJoin={true} />}
            />
            <Route exact path="/event-ended" element={<EventEnded />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
          <VerifyAccount />
          {this.state.hasError ?
            <div className='error-wrapper'>
              <div className='error-div'>
                <p className='error-title'>{t('network_problem')}</p>
                <p className='error-message'>{t('np_text')}</p>
                <button
                  className='btn-reload'
                  onClick={() => window.location.reload()}
                >
                  {t('reload')}
                </button>
              </div>
            </div>
            : null}
        </div>
      </Router>
    )
  }
}

export default withTranslation()(App);