import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../../utils/HelperFunctions';
import classNames from "classnames";
import moment from 'moment';
import * as roomActions from "../../../reducers/room";
import fanoutClient from "../../../utils/FanoutClient";
import streamingImg from "../../../resources/icons/room/streaming.svg";

class StreamingButtonBottomBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            disableBtn: false,
            menu: false,
            streamingLauncher: false
        }

        this.checkStreamingAvailable = this.checkStreamingAvailable.bind(this);
        this.handleStreaming = this.handleStreaming.bind(this);
        this.checkRecording = this.checkRecording.bind(this);
        this.handleStreamingResponse = this.handleStreamingResponse.bind(this);
        this.endEventForAudience = this.endEventForAudience.bind(this);
        this.handleStreamingGetReady = this.handleStreamingGetReady.bind(this);
        this.handleStreamingAtEase = this.handleStreamingAtEase.bind(this);

        fanoutClient.on('streamingResponse', this.handleStreamingResponse);
        fanoutClient.on('streamingGetReady', this.handleStreamingGetReady);
        fanoutClient.on('streamingAtEase', this.handleStreamingAtEase);
    }

    componentDidMount() {
        this.checkStreamingAvailable();
    }

    componentDidUpdate(prevProps) {
        const { menu, streamingLauncher } = this.state;
        const { streaming, displayMessage, eventItem, isRecording, role, conferenceEnded } = this.props;

        if (prevProps.streaming !== streaming) {
            this.setState({
                check: false
            }, () => {
                this.disableStreamingButton();
                if (streaming) {
                    if (displayMessage) {
                        displayMessage('streaming_on', 2000);
                    }
                    if (menu)
                        this.setState({ menu: false });
                } else {
                    if (displayMessage) {
                        displayMessage('streaming_off', 1000);
                    }
                    if (role && role === 'presenter') {
                        this.setState({ menu: true });
                    }
                }

                if ((streamingLauncher && streaming) || (isRecording && !streaming)) {
                    this.checkRecording();
                }

                this.setState({ streamingLauncher: false });
            });
        }

        if (eventItem && prevProps.eventItem !== eventItem) {
            this.checkStreamingAvailable();
        }

        if (conferenceEnded && prevProps.conferenceEnded !== conferenceEnded) {
            if (menu)
                this.setState({ menu: false });
        }
    }

    componentWillUnmount() {
        fanoutClient.removeListener('streamingResponse', this.handleStreamingResponse);
        fanoutClient.removeListener('streamingGetReady', this.handleStreamingGetReady);
        fanoutClient.removeListener('streamingAtEase', this.handleStreamingAtEase);

        if (this.streamingTimeout) {
            clearTimeout(this.streamingTimeout);
        }

        if (this.streamingBtnTimeout) {
            clearTimeout(this.streamingBtnTimeout);
        }
    }

    handleStreamingGetReady(data) {
        const { streamingLauncher } = this.state;
        const { getCallState, role, hideModerator } = this.props;

        if (role && role === 'moderator' && hideModerator) {
            hideModerator();
        }

        if (streamingLauncher && data.startAt) {
            let seconds = moment.utc(new Date(data.startAt)).diff(moment.utc(new Date()), 'seconds');
            this.getReadyTimeout = setTimeout(() => {
                this.setState({
                    check: true
                }, () => {
                    fanoutClient.sendStartStreaming(getCallState());
                });
            }, seconds * 1000);
        }
    }

    handleStreamingAtEase() {
        const { role, prevStateModerator } = this.props;

        if (role && role === 'moderator' && prevStateModerator) {
            prevStateModerator();
        }

        if (this.getReadyTimeout) {
            clearTimeout(this.getReadyTimeout);
        }
    }

    handleStreamingResponse(data) {
        try {
            console.log("handleStreamingResponse response received from server", data);
            if (data && data.result && data.result === 'error') {
                this.setState({
                    check: false,
                    streamingLauncher: false
                });
            }
        }
        catch (error) {
            console.error("Failed handleStreamingResponse", error, data);
        }
    }

    disableStreamingButton() {
        this.setState({
            disableBtn: true
        }, () => {
            this.streamingBtnTimeout = setTimeout(() => {
                this.setState({
                    disableBtn: false
                });
            }, 2000);
        })
    }

    handleStreaming() {
        const { getCallState, streaming, streamingCounter } = this.props;

        if (streamingCounter) {
            fanoutClient.sendStreamingAtEase();
        } else if (!streaming && getCallState) {
            this.setState({ streamingLauncher: true });
            let startAt = moment.utc().add(6, 'seconds').valueOf();
            fanoutClient.sendStreamingGetReady(startAt);
        } else if (streaming && getCallState) {
            this.setState({
                check: true
            }, () => {
                fanoutClient.sendStopStreaming(getCallState());
            });
        }
    }

    checkRecording() {
        const { toggleRecording, isRecording, streaming } = this.props;

        if (this.checkRecordingEnabled() && toggleRecording && ((!streaming && isRecording) || (streaming && !isRecording))) {
            toggleRecording();
        }
    }

    checkRecordingEnabled() {
        const { user, eventItem, role } = this.props;

        let retVal = false;

        if (role && (role === 'presenter' || role === 'moderator') && user && eventItem && eventItem.eventRecording) {
            retVal = true;
        }

        return retVal;
    }

    checkStreamingAvailable() {
        const { eventItem } = this.props;

        if (eventItem && eventItem.startDate) {
            let diffMinutes = moment(new Date(eventItem.startDate)).subtract(2, 'hours').diff(moment(new Date()), 'minutes');
            let diffSeconds = moment(new Date(eventItem.startDate)).subtract(2, 'hours').diff(moment(new Date()), 'seconds');

            if (diffMinutes > 0 || diffSeconds > 0) {
                this.setState({
                    check: true
                }, () => {
                    this.streamingTimeout = setTimeout(() => {
                        this.setState({
                            check: false
                        });
                    }, diffSeconds * 1000);
                });
            }
        }
    }

    endEventForAudience() {
        const { getCallState } = this.props;

        if (getCallState) {
            fanoutClient.endEventForAudience(getCallState());
            this.setState({ menu: false });
        }
    }

    render() {
        const { check, disableBtn, menu } = this.state;
        const { streaming, conferenceEnded, t, streamingCounter, recording } = this.props;

        return (
            <li className={classNames("toggle-streaming", { 'active': streaming }, { 'disable': check || disableBtn || conferenceEnded || (!recording || (recording && recording !== 'ready'))})}>
                {menu ?
                    <div className="bubble-tip streaming-btn">
                        <a
                            className="icon-close"
                            title="Close"
                            onClick={() => this.setState({ menu: false })}
                        />
                        <p className='text'>{t('end_event_audience')}</p>
                        <p className='text sm'>{t('action_not_reversible')}</p>
                        <div className='menu-flex'>
                            <a onClick={this.endEventForAudience}>
                                {t('yes')}
                            </a>
                            <a onClick={() => this.setState({ menu: false })}>
                                {t('no')}
                            </a>
                        </div>
                        <div className="anchor-popup" />
                    </div>
                    : null
                }
                <a
                    data-tip
                    data-for="streaming"
                    title={conferenceEnded ? t('stream_ended') : streaming || streamingCounter ? this.checkRecordingEnabled() ? t('pause_stream_record') : t('pause_stream') : this.checkRecordingEnabled() ? t('start_stream_record') : t('start_stream')}
                    onClick={this.handleStreaming.bind(this)}
                >
                    <img src={streamingImg} alt='img' />
                    <div>
                        <span style={this.checkRecordingEnabled() ? { whiteSpace: "initial" } : {}}>{conferenceEnded ? t('stream_ended') : streaming || streamingCounter ? this.checkRecordingEnabled() ? t('pause_stream_record') : t('pause_stream') : this.checkRecordingEnabled() ? t('start_stream_record') : t('start_stream')}</span>
                    </div>
                </a>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        streaming: state.room.streaming,
        streamingCounter: state.room.streamingCounter,
        recording: state.room.recording,
        conferenceEnded: (state.room.conference && (state.room.conference === 'ended_for_audience' || state.room.conference === 'ended')) || (state.room.status && (state.room.status === 'ended_for_audience' || state.room.status === 'ended')),
        user: state.firebase.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        displayMessage: (message, timer, type = null) => {
            dispatch(roomActions.displayMessage({ message: message, timer: timer, type: type }));
        }
    };
};

const StreamingButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StreamingButtonBottomBar);

export default withTranslation()(withRouter(StreamingButtonContainer));
