import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    init: false,
    user: null,
    userLoaded: false,
    auth: false
}

export const getUser = createAsyncThunk(
    'firebase/getUser',
    async (uid) => {
        // const response = await firebaseClient.getUserData(uid);
        // return { user: response.data }
        return { user: 'test' }
    }
)

export const firebaseSlice = createSlice({
    name: 'firebase',
    initialState,
    reducers: {
        initFirebase: (state, action) => {
            return Object.assign({}, state, action.payload);
        },
        setUser: (state, action) => {
            return Object.assign({}, state, action.payload);
        },
        clear: () => {
            return Object.assign({}, initialState);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUser.fulfilled, (state, action) => {
            return Object.assign({}, state, action.payload);
        })
    }
});

const { actions, reducer } = firebaseSlice;

// export individual action creator functions
export const {
    initFirebase,
    setUser,
    clear
} = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;