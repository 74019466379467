import { createSlice } from '@reduxjs/toolkit';

const initialState = {}

export const appSlice = createSlice({
    name: 'knocks',
    initialState,
    reducers: {
        knocked: (state, action) => {
            if (!action.payload || !action.payload.rid) {
                console.warn('knock action - no valid payload', action.payload)
                return state;
            }
            let update = { [action.payload.rid]: action.payload }
            update[action.payload.rid].mine = true;
            return Object.assign({}, state, update);
        },
        knock: (state, action) => {
            if (!action.payload || !action.payload.rid) {
                console.warn('knock action - no valid payload', action.payload)
                return state;
            }
            let update = { [action.payload.rid]: action.payload }
            return Object.assign({}, state, update);
        },
        accept: (state, action) => {
            if (!action.payload || !action.payload.rid) {
                console.warn('knock accept - no valid payload', action.payload)
                return state;
            }
            let knock = state[action.payload.rid];
            if (!knock) {
                console.warn('Could not find knock data');
                return state;
            }

            let knocksState = state;
            knocksState[action.payload.rid].accepted = true;

            return knocksState;
        },
        reject: (state, action) => {
            if (!action.payload || !action.payload.rid) {
                console.warn('knock reject - no valid payload', action.payload)
                return state;
            }
            // Remove it
            const next = { ...state };
            delete next[action.payload.rid];
            return next;
        },
        revoke: (state, action) => {
            if (!action.payload || !action.payload.rid) {
                console.warn('knock revoke - no valid payload', action.payload)
                return state;
            }
            // Remove it
            const next = { ...state };
            delete next[action.payload.rid];
            return next;
        },
        setKnocksList: (state, action) => {
            let knocksList = {};
            if (action.payload && action.payload.length) {
                action.payload.filter((item, i) => {
                    knocksList[`${action.payload[i].rid}`] = action.payload[i];
                    return item;
                });
            }

            return Object.assign({}, state, knocksList);
        },
        clear: () => {
            return Object.assign({}, initialState);
        },
    },
});

const { actions, reducer } = appSlice;

// export individual action creator functions
export const {
    knocked,
    knock,
    reject,
    accept,
    clear,
    revoke,
    setKnocksList
} = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;