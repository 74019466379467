import React, { useEffect, useRef, Component } from "react";
import { VariableSizeList as List, shouldComponentUpdate as shouldRWUpdate } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import { withTranslation } from 'react-i18next';
import classNames from "classnames";
import ClickOutside from '../Widget/ClickOutside';
import fanoutClient from "../../utils/FanoutClient";

class MMSDList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scrollEnd: true,
            moreId: null
        }
        this.listRef = React.createRef();
        this.getRowHeight = this.getRowHeight.bind(this);
        this.setRowHeight = this.setRowHeight.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.checkScroll = this.checkScroll.bind(this);
        this.shouldComponentUpdate = shouldRWUpdate.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
    }

    componentDidMount() {
        const { childRef } = this.props;

        if (childRef)
            childRef(this);
    }

    componentDidUpdate(prevProps) {
        const { moreId, scrollEnd } = this.state;
        const { attendeesChatOpened, messages } = this.props;

        if (moreId && !attendeesChatOpened && prevProps.attendeesChatOpened !== attendeesChatOpened) {
            this.setState({
                moreId: null
            });
        }

        if ((prevProps.messages.length !== messages.length && scrollEnd) || (prevProps.attendeesChatOpened !== attendeesChatOpened && attendeesChatOpened && messages.length)) {
            this.scrollToBottom();
        }
    }

    componentWillUnmount() {
        const { moreId } = this.state;
        const { childRef } = this.props;

        if (childRef)
            childRef(undefined);

        if (moreId) {
            this.setState({
                moreId: null
            });
        }
    }

    getRowHeight(index) {
        // console.log('getRowHeight-' + index, this.state[index] + 8)
        return this.state[index] + 8;
    }

    setRowHeight(index, size) {
        // console.log('setRowHeight-' + index, size)
        if (this.listRef && this.listRef.current) {
            this.listRef.current.resetAfterIndex(0);
        }
        this.setState({ [index]: size });
    }

    scrollToBottom() {
        const { messages } = this.props;
        this.setState({ scrollEnd: true });
        if (this.listRef && this.listRef.current && messages && messages.length) {
            this.listRef.current.scrollToItem(messages.length - 1, 'end');
        }
    }

    checkScroll() {
        const { moreId } = this.state;

        if (moreId) {
            this.setState({
                moreId: null
            })
        }

        if (this.listRef && this.listRef.current && this.listRef.current._outerRef) {
            let reference = this.listRef.current._outerRef;
            const bottom = (reference.scrollTop + 50) + reference.clientHeight >= reference.scrollHeight;
            this.setState({
                scrollEnd: bottom
            });
        }
    }

    banUserByMessage(messageId) {
        const { getCallState } = this.props;
        if (messageId && getCallState) {
            this.setState({
                moreId: null
            }, () => {
                console.log('ban the user', messageId);
                fanoutClient.banUserByMessage(messageId, getCallState());
            })
        }
    }

    deleteMessage(messageId) {
        const { getCallState } = this.props;
        if (messageId && getCallState) {
            this.setState({
                moreId: null
            }, () => {
                console.log('delete message', messageId);
                fanoutClient.deleteMessage(messageId, getCallState());
            })
        }
    }

    clickUser(index) {
        const { moreId } = this.state;
        const { role } = this.props;

        if (role && ((role === 'presenter') || (role === 'moderator'))) {
            this.setState({
                moreId: ((moreId === null) || (moreId && moreId !== index)) ? index : null
            });
        }
    }

    renderMessage(content) {
        return " " + content;
    }

    render() {
        const { scrollEnd, moreId } = this.state;
        const { messages, t, role } = this.props;

        const styles = {
            receivedMessage: {
                display: "flex",
                width: "100%"
            },
            receivedMessageContainer: {
                display: "flex",
                flex: "0 0 auto",
                justifyContent: "flex-start",
                width: "100%"
            }
        };

        const getItemSize = index => {
            // console.log('index', index, this.state[index]);
            return this.state[index] || 82;
        };

        let Row = ({ index, style, data }) => {
            const rowRef = useRef({});

            useEffect(() => {
                if (rowRef && rowRef.current) {
                    this.setRowHeight(index, rowRef.current.clientHeight);
                }
            }, [rowRef, index]);

            return (
                <div style={style}>
                    <div ref={rowRef} style={styles.receivedMessageContainer}>
                        <div className='mmsd-item' style={styles.receivedMessage}>
                            {/* <img src={userPlaceholder} loading='lazy' width="33" height="33" alt='avatar' /> */}
                            <div className='item-text'>
                                <span
                                    className={classNames('item-name', { 'more': (role && ((role === 'presenter') || (role === 'moderator'))) })}
                                    onClick={() => this.clickUser(index)}
                                    style={{ pointerEvents: "auto" }}
                                >
                                    {data[index].name}
                                </span>
                                {data[index].content && data[index].content.indexOf && data[index].content.indexOf('</a>') !== - 1 ?
                                    <span
                                        className="item-message"
                                        dangerouslySetInnerHTML={{ __html: this.renderMessage(data[index].content) }}
                                    />
                                    :
                                    <span className="item-message">
                                        {this.renderMessage(data[index].content)}
                                    </span>
                                }
                                {moreId !== null && moreId === index ?
                                    <ClickOutside
                                        className={classNames('more-menu', { 'bottom': messages && messages.length && ((messages.length - 3) < index) })}
                                        clickOutside={() => this.setState({ moreId: null })}
                                    >
                                        <button
                                            className='btn btn-ban'
                                            onClick={() => this.banUserByMessage(data[index].id)}
                                        >
                                            {`${t('ban_user')} ${data[index].name}`}
                                        </button>
                                        <button
                                            className='btn btn-ban'
                                            onClick={() => this.deleteMessage(data[index].id)}
                                        >
                                            {t('delete_message')}
                                        </button>
                                    </ClickOutside>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <AutoSizer onResize={this.checkScroll}>
                {({ height, width }) => (
                    <div>
                        <List
                            height={height}
                            itemCount={messages.length}
                            itemSize={getItemSize}
                            itemData={messages}
                            className="mmsd-list"
                            width={width}
                            ref={this.listRef}
                            onScroll={this.checkScroll}
                        >
                            {Row}
                        </List>
                        {!scrollEnd &&
                            <div className='chat-scroll-off' onClick={this.scrollToBottom}>
                                {t('chat_paused')}
                            </div>
                        }
                    </div>
                )}
            </AutoSizer>
        )
    }
}

export default withTranslation()(MMSDList);