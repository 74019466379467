export const TRANSLATIONS_EN = {
    app_title: "Welcome to Strmz.io",
    not_found: "Not Found",
    about: "About",
    partners: "Partners",
    pricing: "Pricing",
    home: "Home",
    help: "Help",
    sign_up: "Sign up",
    create_event: "Create event",
    privacy: "Privacy",
    terms: "Terms",
    contact_us: "Contact us",
    sign_in: "Sign in",
    agree_1: "I agree to the Strmz.io ",
    agree_2: "I agree to the",
    terms_of_service: "Terms of Service",
    and: "and",
    my_events: "My Events",
    my_profile: "My Profile",
    my_subscription: "My Subscription",
    my_hosts: "My Hosts",
    privacy_policy: " privacy policy",
    google_sign: "Sign in with Google",
    apple_sign: "Sign in with Apple",
    microsoft_sign: "Sign in with Microsoft",
    mail_sign: "Sign in with Mail",
    google_sign_up: "Sign up with Google",
    apple_sign_up: "Sign up with Apple",
    microsoft_sign_up: "Sign up with Microsoft",
    mail_sign_up: "Sign up with email",
    logout: "Logout",
    edit_event: "Edit event",
    event_description: "Event Description",
    event_details: "Event Details",
    audience_size: "Audience size",
    reserved_tickets: "Reserved tickets",
    duration: "Duration",
    date: "Date",
    time: "Time",
    event_link: "Event link",
    link_copied: "Link copied",
    join: "Join",
    event_registration: "Event registration",
    register_for_event: "Register for event",
    event_text: "To register for this event, please provide the following details",
    name: "Name",
    hour: "hour",
    hours: "hours",
    copy_audience_link: "Copy audience link",
    streaming_on: "Your event is being streamed",
    streaming_off: "Your event is no longer being streamed",
    knock_on: "You raised hand to join the live stream",
    knock_off: "No longer asking to join the live stream",
    move_room: "Moving into live stream",
    move_audience: "Moving to audience",
    event_over: "The event is over. Thank you for participating!",
    event_begin: "The event will begin soon",
    event_not_started: "Event not started yet",
    host_off: "Please wait",
    hosts: "Hosts",
    hosts_guests: "Hosts and Guests",
    audience: "Audience",
    chat: "Chat",
    chat_hosts: "Chat/Hosts",
    open_chat_hosts: "Open Chat/Hosts",
    close_chat_hosts: "Close Chat/Hosts",
    swap_view: "Swap View",
    start_video: "Start video",
    leave: "Leave",
    cancel: "Cancel",
    exit_stream: "Exit Stream",
    confirm_exit: "Confirm Exit",
    leave_and: "Leave and..",
    end_event_everyone: "End event for everyone",
    let_event_continue: "Let event continue",
    confirm: "Confirm",
    knock: "Raise Hand",
    participants: "Participants",
    move_to_audience: "Move To Audience",
    remove: "Remove",
    knock_panel: "Hands Raised",
    knocks_queue: "In queue",
    no_filtered_users: "No filtered users",
    enter_search_text: "Enter search text",
    guests: "Guest Speakers",
    knocks: "Hands Raised",
    streaming: "Streaming",
    play: "Play",
    chat_paused: "Chat paused due to scroll",
    pick_your_emoji: "Pick your emoji…",
    close: "Close",
    enter_your_message: "Enter your message",
    start_typing_search: "Start typing to search",
    some_error: "Some error occurred",
    please_wait: "Please wait",
    loading: "Loading",
    connecting: "Connecting",
    error: "Error",
    pairing: "Pairing",
    ok: "Ok",
    pair: "Pair",
    different_provider: "You have already signed up with a different auth provider for that email.",
    like_pair: "Would you like to pair accounts?",
    closed_popup: "You closed the pop-up window before the operation was completed.",
    login_cancelled: "Login cancelled",
    your_name: "Your Name",
    title: "Your Title",
    company: "Your Company",
    email: "Your Email",
    your_email: "Your email",
    your_password: "Your password",
    your_confirm_password: "Your confirm password",
    sign_with_credentials: "To join the event, please sign in with your company credentials.",
    sign_email: "Enter the email to which we will send you the code.",
    sign_code_1: "A code has been sent to",
    sign_code_2: "Please enter that code in the field below.",
    verification_email_sent_1: "An email has been sent to ",
    verification_email_sent_2: ", containing a verification link",
    click_to_finalize_registration: "Please click on the link to finalize registration.",
    check_spam_1: "If the verification email has not arrived after two minutes,",
    check_spam_2: "check your spam folder",
    code: "Code",
    enter_code: "Enter code",
    email_p: "Email",
    password: "Password",
    confirm_password: "Confirm Password",
    access_forbidden: "Access to the event is denied and you are not allowed to enter the event.",
    access_denied: "Access denied",
    not_authorized: "Not authorized to view this page",
    event_not_found: "Event not found",
    event_over_missed: "This event is over. Sorry you missed it!",
    event_cancelled: "Sorry! It looks like this event has been canceled by the host.",
    unknown_error: "Unknown error",
    verifying: "Verifying",
    verify_email: "Verify email",
    ban_user: "Ban",
    banned: "Banned",
    lift_the_ban: "Lift the ban",
    security: "Security",
    password_invalid: "The password is invalid",
    no_user_email: "There is no user with this email",
    create_account: "Create account",
    reset_password: "Reset password",
    reset_message: "A mail for reset password has been sent to",
    back: "Back",
    no_user_1: "Don’t have an account on Strmz.io?",
    no_user_2: "Click ",
    no_user_3: " to create one!",
    here: "here",
    exist_user: "Already have an account on Strmz.io? ",
    pass_text_2: "Enter your new password for Strmz.io. Password rules:",
    pass_text: "Minimum 8 characters, at least one letter, at least one number and at least one special character.",
    error_another_account: "The email address is already in use by another account.",
    valid_1: "You must enter your email",
    valid_2: "You must enter a valid email",
    valid_3: "You must enter your password",
    valid_4: "You must enter a valid password",
    valid_5: "You must enter your confirmation password",
    valid_6: "You must enter a valid confirmation password",
    valid_7: "Passwords do not match",
    verify_message: "We have sent a verification email to your specified email address. Please check your inbox for the email and click on the verification link to complete sign-up.",
    send_again: "Send again",
    sending_verification_email: "Sending verification email",
    sending: "Sending",
    error_sending_verification_email: "Could not send verification email",
    sended_again: "Verification email sent again",
    streaming_error_msg_1: "Too many started events!",
    delete_message: "Delete message",
    end_message: "The event has ended.\nThank you for participating!",
    verify_domain: "This is a private event and the email address you provided does not match the list of domains that are approved to attend. Please update your email address.",
    sign_in_text: "Once you have ticked the box to accept our terms of service, please login using the email address and password you’d like to associate with your new account. Note that the email address you choose to sign in with will become your new Account ID.",
    event_participant_terms_of_use: " Strmz.io Event Participant Terms of Use",
    start_stream_record: "Start Stream/Record",
    pause_stream_record: "Pause Stream/Record",
    cancel_stream_record: "Cancel Stream/Record",
    start_stream: "Start Stream",
    pause_stream: "Pause Stream",
    cancel_stream: "Cancel Stream",
    sign_in_no_user_1: "Never attended a Strmz.io event before?\nClick ",
    sign_in_no_user_2: " to setup your login profile.",
    sign_up_lready_user_1: "Already have a login profile on Strmz.io?\nClick ",
    sign_up_lready_user_2: " to sign in.",
    register: "Register",
    reg_title: "Registration",
    reg_text: "Thank you. You are registered for this event. See you then!",
    inbound_video: "Inbound Video",
    overruns_text: "This event has significantly run over the original time. Do you want the event to continue?",
    event_overrun: "Event Overrun",
    yes: "Yes",
    no: "No",
    lbm_1: "If you are having problems seeing or hearing other participants or if you are seeing a ",
    lbm_2: "Connection Error",
    lbm_3: " notification, it is likely that you are having internet bandwidth issues. Toggle to ",
    lbm_4: "Low bandwidth mode",
    lbm_5: " to stop downloading video from other participants - your audio will improve and you will still be able to see any screens that are shared.",
    lbm_6: "Note: Enabling ",
    lbm_7: " will not impact anyone else - they will still be able to see and hear you.",
    confirm_text_1: "It looks like you’re registering to join the audience of the event. \nIs that right?",
    confirm_text_2: "(If you were invited to attend the event as a host, moderator or guest, click ",
    confirm_text_3: " to go back and enter the email address that you received the invitation on)",
    continue_text: "You are the only host remaining in the event. When you leave, if the event is without a host for 5 minutes, the event will end automatically for all participants.",
    stream_panel: "Stream panel",
    end_event_audience: "End event for audience?",
    stream_ended: "Stream Ended",
    no_streaming_moderator: "An event host must be present in the event before streaming can begin",
    too_many_started_events: "Too many started events",
    streaming_error: "Streaming error",
    action_not_reversible: "(action is not reversible)",
    network_problem: "Network Problem",
    np_text: "Please check your internet connection or try reload the page.",
    reload: "Reload",
    tfae: "Timeout for authentication expired",
    tfre: "Timeout for reconnection expired",
    could_not_complete_operation: "Could not complete operation",
    start_streaming_text: "Stream starts in....",
    let_event_message: "Leaving the event, now returning to event page",
    not_support_browser_title: "Please use Safari on Apple devices",
    not_support_browser_text: "For an optimal viewing experience of strmz.io events on an Apple device, please use the Safari browser.",
    register_text: "You are registered for this event. You can join the audience up to 30 minutes before the start time of the event. See you then!"
};
