import React, { Component } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Cookies from "js-cookie";
import classNames from "classnames";
import { withRouter, isMobile, getReconnectToken } from '../../utils/HelperFunctions';
import wssClient from '../../utils/FanoutClient';
import { Defines } from '../../utils/FanoutDefines';
import Loader from '../Widget/Loader';
import { v1 as uuidv1 } from 'uuid';
import * as chatActions from "../../reducers/chat";
import * as roomActions from "../../reducers/room";
import * as appActions from '../../reducers/app';
import UserInfoForm from "./../Room/UserInfoForm";
import StaticPage from '../Widget/StaticPage';
import Room from "../Room/Room";

var today = new Date();
today.setDate(today.getDate() + 365);
const default_cookies_param = {
    path: "/",
    expires: today,
    secure: true,
    sameSite: 'none',
};

class EventPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            role: null,
            linkId: null,
            isError: false,
            knockGranted: false,
            show: false,
            username: null,
            userPhoto: null,
            name: null,
            userId: null,
            eventId: null,
            errorCode: null,
            registerOverlay: false,
            registerLoading: false,
            showConfirm: false
        }

        this.sessionId = uuidv1();
        this.handleVerifyLinkResponse = this.handleVerifyLinkResponse.bind(this);
        this.handleAuthResponse = this.handleAuthResponse.bind(this);
        this.handleReconnectResponse = this.handleReconnectResponse.bind(this);
        this.handleRegisterResponse = this.handleRegisterResponse.bind(this);
        this.getCallState = this.getCallState.bind(this);
        this.handleExit = this.handleExit.bind(this);
        this.handleJoin = this.handleJoin.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.renderError = this.renderError.bind(this);

        // TODO change this after to get rid of hardcoding and instead request ip from server
        wssClient.on('reconnectResponse', this.handleReconnectResponse);
        wssClient.on('registerResponse', this.handleRegisterResponse);
        wssClient.on('verifyLinkResponse', this.handleVerifyLinkResponse);
        this.eventItem = null;
    }

    setDefaultCookieParams() {
        let videoEnabled = Cookies.get('videoEnabled');
        // console.log('videoEnabled', videoEnabled);

        if (!videoEnabled || (videoEnabled && (typeof videoEnabled === 'string' || videoEnabled instanceof String) && videoEnabled.toLowerCase() !== 'true')) {
            Cookies.set('videoEnabled', true, default_cookies_param);
        }

        Cookies.set("maxVideoForwarding", isMobile() ? 4 : 16, default_cookies_param);

        let audioTransparentMode = Cookies.get('audioTransparentMode');
        // console.log('audioTransparentMode', audioTransparentMode);

        if (!audioTransparentMode) {
            Cookies.set("audioTransparentMode", true, default_cookies_param);
        }
    }

    async componentDidMount() {
        const { params, link_id } = this.props;

        let linkId = link_id || (params && params.linkId ? params.linkId : null)

        this.setState({
            linkId,
            isError: !linkId
        }, async () => {
            await wssClient.connectToFanout({ linkId, username: 'unknown' });
        });

        this.setDefaultCookieParams();
    }

    componentDidUpdate(prevProps, prevState) {
        function shallowEqual(object1, object2) {
            if (!object1 && !object2)
                return true;
            if ((!object1 && object2) || (object1 && !object2))
                return false;
            const keys1 = Object.keys(object1);
            const keys2 = Object.keys(object2);
            if (keys1.length !== keys2.length) {
                console.log('Difference in length %s', keys1, keys2)
                return false;
            }
            for (let key of keys1) {
                if (object1[key] !== object2[key]) {
                    console.log('Difference in key %s', key)
                    return false;
                }
            }
            return true;
        }

        console.log('EventPage componentDidUpdate', prevProps, this.props, prevState, this.state);

        shallowEqual(prevProps, this.props);
        shallowEqual(prevState, this.state);
    }

    componentWillUnmount() {
        wssClient.removeListener('reconnectResponse', this.handleReconnectResponse);
        wssClient.removeListener('registerResponse', this.handleRegisterResponse);
        wssClient.removeListener('verifyLinkResponse', this.handleVerifyLinkResponse);

        wssClient.disconnectFromFanout(this.getCallState());

        if (this.exitTimeout) {
            clearTimeout(this.exitTimeout);
        }
        if (this.messageTimeout) {
            clearTimeout(this.messageTimeout);
        }
    }

    getCallState() {
        return {};
    }

    handleVerifyLinkResponse(data) {
        try {
            const { eventId, role } = this.state;
            console.log("handleVerifyLinkResponse response received from server", data);
            if (data.event && data.event.eventId) {
                if (data.event.eventItem) {
                    console.log("handleVerifyLinkResponse About to set eventItem", data.event.eventItem);
                    this.eventItem = data.event.eventItem;
                    if (data.event.eventItem) {
                        if (data.event.eventItem.status && data.event.eventItem.status === 'ended') {
                            this.eventItem = null;
                            this.setState({
                                eventId: null,
                                isError: true,
                                errorCode: Defines.Response.Gone
                            });
                        } else if (data.event.eventItem.status && (data.event.eventItem.status === 'deleted' || data.event.eventItem.status === 'cancelled')) {
                            this.eventItem = null;
                            this.setState({
                                eventId: null,
                                isError: true,
                                errorCode: Defines.Response.Locked
                            });
                        } else if (!eventId || !role) {
                            this.setState({
                                eventId: data.event.eventId,
                                role: data.event.role,
                            }, () => {
                                //Check autojoin
                                if (this.props.autoJoin) {
                                    // Get all registration data
                                    const username = localStorage.getItem('username');
                                    let userInfoData = localStorage.getItem('userInfo');
                                    console.log("Autojoin", username, userInfoData);

                                    if (userInfoData) {
                                        userInfoData = JSON.parse(userInfoData);
                                    } else {
                                        let { redirectToLogin, navigate } = this.props;
                                        userInfoData = null;
                                        if (redirectToLogin) {
                                            if (redirectToLogin.indexOf(':linkId') !== -1)
                                                redirectToLogin = redirectToLogin.replace(':linkId', encodeURIComponent(this.state.eventId));
                                            // if(redirectToLogin.indexOf(':company')!==-1)
                                            //     redirectToLogin = redirectToLogin.replace(':company', encodeURIComponent(company));
                                            if (redirectToLogin.indexOf(':event-title') !== -1)
                                                redirectToLogin = redirectToLogin.replace(':event-title', encodeURIComponent(this.eventItem.title));
                                            navigate(redirectToLogin);
                                        } else {
                                            this.setState({
                                                show: false,
                                                isError: true,
                                                message: "Not authenticated",
                                            });
                                        }
                                    }

                                    if (username || (userInfoData && Object.keys(userInfoData).length)) {
                                        this.setState({
                                            username: userInfoData && userInfoData.username ? userInfoData.username : username ? username : '',
                                            title: userInfoData && userInfoData.title ? userInfoData.title : '',
                                            company: userInfoData && userInfoData.company ? userInfoData.company : '',
                                            email: userInfoData && userInfoData.email ? userInfoData.email : ''
                                        }, () => {
                                            // Try to join
                                            const { eventId } = this.state;
                                            wssClient.reconnectAsMe(userInfoData.email, eventId).then((data) => {
                                                console.log("Reconnected", data);
                                                this.setState({
                                                    show: true
                                                });
                                            }).catch((e) => {
                                                console.error("handleVerifyLinkResponse error received from server", e);
                                                this.setState({
                                                    isError: true,
                                                })
                                            });
                                        });
                                    } else {
                                        this.setState({
                                            show: false,
                                            isError: true,
                                            message: "Not authenticated",
                                        });
                                    }
                                }
                            });
                        }
                    } else {
                        this.eventItem = null;
                        this.setState({
                            eventId: null,
                            isError: true,
                            errorCode: data.status || 404
                        });
                    }
                } else {
                    this.eventItem = null;
                    this.setState({
                        eventId: null,
                        isError: true,
                        errorCode: data.status || 404
                    });
                }
            } else {
                this.eventItem = null;
                this.setState({
                    eventId: null,
                    isError: true,
                    errorCode: data.status || 404
                });
            }
        }
        catch (error) {
            console.error("Failed verifyLinkResponse", error, data);
            this.setState({
                isError: true
            });
        }
    }

    async handleRegister(username, emailVerificationCode, title, company, email) {
        const { eventId } = this.state;

        let reconnectToken = getReconnectToken({ eventId, email });

        if (reconnectToken) {
            this.setState({
                registerOverlay: true,
                registerLoading: false
            });
        } else {
            this.setState({
                registerLoading: true
            }, async () => {
                return wssClient.register(this.state.linkId, username, emailVerificationCode, title, company, email).then((data) => {
                    if (data) {
                        this.setState({
                            registerOverlay: true,
                            registerLoading: false
                        });
                    }
                }).catch((e) => {
                    this.setState({
                        isError: true
                    })
                });
            });
        }
    }

    handleAuthResponse(data) {
        try {
            if (data && data.status && data.status === Defines.Response.NotAcceptable) {
                this.setState({
                    showConfirm: true,
                    show: false
                });
            } else {
                const { uid, name, role, username, picture, eventId } = data || {};
                console.log("handleAuthResponse response received from server", data);
                this.setState({
                    userId: uid || null,
                    role: role || 'banned',
                    userPhoto: picture || null,
                    name,
                    username,
                    show: true,
                    isError: !uid,
                    eventId: eventId
                });
            }
        }
        catch (error) {
            console.error("Failed handleAuthResponse", error, data);
        }
    }

    handleReconnectResponse(data) {
        try {
            console.log("handleReconnectResponse response received from server", data);
            if (data.result && data.result !== 'error') {
                this.setState({
                    role: data.user ? data.user.role : 'banned',
                    user_id: data.user ? data.user.user_id : null,
                    userPhoto: data.user ? data.user.picture : null,
                    isError: !data.user,
                    errorCode: !data.user && data.status ? data.status : null
                }, () => {
                    if (!data.user)
                        return;
                    const { role } = this.state;
                    const { user, userLoaded, showSignIn, signIn } = this.props;

                    if (role && (role === 'moderator' || role === 'presenter') && !user && userLoaded && !signIn && showSignIn) {
                        showSignIn(true);
                    }
                });
            }
        }
        catch (error) {
            console.error("Failed handleReconnectResponse", error, data);
        }
    }

    handleRegisterResponse(data) {
        try {
            console.log("handleRegisterResponse response received from server", data);
            if (data.result && data.result === 'error') {
                this.setState({
                    isError: true,
                    errorCode: data.status && data.status !== 200 ? data.status : null
                });
            }
        }
        catch (error) {
            console.error("Failed handleRegisterResponse", error, data);
        }
    }

    handleJoin(auth, username, emailVerificationCode, title, company, avatar, email, skipauth = false, role = null) {
        let { eventId } = this.state;
        let { redirectOnJoin, navigate } = this.props;

        if (redirectOnJoin) {
            if (redirectOnJoin.indexOf(':linkId') !== -1)
                redirectOnJoin = redirectOnJoin.replace(':linkId', encodeURIComponent(this.state.linkId));
            // if(redirectOnJoin.indexOf(':company')!==-1)
            //     redirectOnJoin = redirectOnJoin.replace(':company', encodeURIComponent(company));
            if (redirectOnJoin.indexOf(':event-title') !== -1)
                redirectOnJoin = redirectOnJoin.replace(':event-title', encodeURIComponent(this.eventItem.title));
            navigate(redirectOnJoin);
        }

        if (auth) {
            this.setState({
                show: true,
                username,
                eventId: null
            }, () => {
                wssClient.join(this.state.linkId, username, emailVerificationCode, title, company, avatar, email, skipauth, role, { eventId })
                    .then(this.handleAuthResponse).catch((e) => {
                        console.error("handleJoin error received from server", e);
                        this.setState({
                            isError: true,
                        })
                    });
            });
        } else {
            this.setState({
                show: true,
                username
            });
        }
    }

    handleExit(end = false) {
        const { hideMessage, navigate, redirectOnEnd } = this.props;

        if (end) {
            if (hideMessage)
                hideMessage();

            if (redirectOnEnd)
                navigate(redirectOnEnd);
        } else {
            this.setState({
                show: false
            }, () => {
                if (hideMessage)
                    hideMessage();
            });
        }
    }

    renderError(errorCode) {
        const { t } = this.props;

        let message = '', title = '', notification = false, showButton = false;

        switch (errorCode) {
            case Defines.Response.Forbidden:
                message = t('access_forbidden');
                title = t('access_denied');
                break;
            case Defines.Response.Unauthorized:
                message = t('not_authorized');
                title = t('error');
                break;
            // case Defines.Response.NotFound:
            //     message = t('event_not_found');
            //     title = t('error');
            //     break;
            case Defines.Response.NotFound:
                message = t('event_over_missed');
                notification = true;
                console.log('NotFound-EVENT')
                break;
            case Defines.Response.Gone:
                message = t('event_over_missed');
                notification = true;
                break;
            case Defines.Response.Locked:
                message = t('event_cancelled');
                notification = true;
                break;
            default:
                message = t('could_not_complete_operation');
                title = t('network_problem');
                showButton = true;
                break;
        }
        return (
            <div className='error-wrapper'>
                <div className='error-div'>
                    {title ? <p className='error-title'>{title}</p> : null}
                    <p className={classNames('error-message', { 'notification': notification })}>{message}</p>
                    {showButton ?
                        <button
                            className='btn-reload'
                            onClick={() => window.location.reload()}
                        >
                            {t('reload')}
                        </button>
                        : null
                    }
                </div>
            </div>
        );
    }

    render() {
        const { registerOverlay, registerLoading, isError, errorCode, eventId, userId,
            show, username, userPhoto, role, name, showConfirm } = this.state;
        const { t, user } = this.props;
        const { eventItem } = this;
        console.log('EventPage render', user, role, eventId);


        return (
            <div className='room-wrapper'>
                {(isError) ?
                    this.renderError(errorCode) :
                    (!show) ?
                        <UserInfoForm
                            joinUser={this.handleJoin}
                            registerUser={this.handleRegister}
                            eventItem={eventItem}
                            role={role}
                            showConfirm={showConfirm}
                            hideConfirm={() => this.setState({ showConfirm: false })}
                        />
                        : (role && eventId) ?
                            <Room
                                role={role}
                                user={user}
                                uid={userId}
                                name={name ? name : null}
                                username={username ? username : (name ? name : '')}
                                userPhoto={userPhoto || null}
                                eventId={eventId}
                                eventItem={eventItem}
                                handleExit={this.handleExit}
                                handleError={this.renderError}
                                joinUser={this.handleJoin}
                            />
                            : <Loader
                                text={t('connecting')}
                                dots
                            />
                }
                {registerOverlay ?
                    <StaticPage
                        title={t('reg_title')}
                        text={t('reg_text')}
                        close={() => this.setState({ registerOverlay: false, registerLoading: false })}
                    />
                    : registerLoading ?
                        <Loader
                            text={t('please_wait')}
                            dots
                            bg
                        />
                        : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        userLoaded: state.firebase.userLoaded,
        signIn: state.app.signIn,
        userInfo: state.room.userInfo,
        eventItem: state.room.eventItem,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearMessages: () => {
            dispatch(chatActions.clearMessages({ messages: [] }));
        },
        displayMessage: (message, timer) => {
            dispatch(roomActions.displayMessage({ message: message, timer: timer }));
        },
        hideMessage: () => {
            dispatch(roomActions.hideMessage());
        },
        showSignIn: (value) => {
            const terms = localStorage.getItem('nfbTerms') === 'true';
            dispatch(appActions.showSignIn({ signIn: value, signUp: !terms }));
        }
    };
};

const EventPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EventPage);

export default withTranslation()(withRouter(EventPageContainer));