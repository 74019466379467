import { createSlice } from '@reduxjs/toolkit';

const initialState = {
}

export const bannedSlice = createSlice({
    name: 'bannedUsers',
    initialState,
    reducers: {
        updateListItem: (state, action) => {
            if(action.payload) {
                const next = { ...state };
                
                if(action.payload.banned && action.payload.user && action.payload.user.user_id) {
                    next[action.payload.user.user_id] = action.payload.user;
                }
                else if(!action.payload.banned && action.payload.user && action.payload.user.user_id) {
                    delete next[action.payload.user.user_id];
                }

                return next;
            }
            else {
                return Object.assign({}, initialState);
            }
        },
        updateList: (state, action) => {
            if(action.payload) {
                // Remove it
                const next = { ...state };
                for (const [key, value] of Object.entries(action.payload)) {
                    // console.log(`${key}: ${value}`);
                    if(value)
                        next[key] = value;
                    else
                        delete next[key];
                }

                return next;
            }
            else {
                return Object.assign({}, initialState);
            }
        },
        clearList: () => {
            return Object.assign({}, initialState);
        },
    },
});

const { actions, reducer } = bannedSlice;

// export individual action creator functions
export const {
    updateListItem,
    updateList,
    clearList
} = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;