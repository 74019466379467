import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    messages: [],
    newMessage: false,
    showEmoji: false,
    emoji: null
}

export const appSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setMessages: (state, action) => {
            return Object.assign({}, state, action.payload);
        },
        deleteMessageById: (state, action) => {
            let id = action.payload.id, messages = [...state.messages];

            const newArrMessages = messages.filter(object => {
                return object.id !== id;
            });

            return {
                ...state,
                messages: newArrMessages
            };
        },
        deleteMessagesByIds: (state, action) => {
            let ids = action.payload.ids, messages = [...state.messages];

            const newArrMessages = messages.filter(objectA => {
                return !ids.find(id => {
                    return id && objectA.id === id;
                });
            });

            return {
                ...state,
                messages: newArrMessages
            };
        },
        clearMessages: () => {
            return Object.assign({}, initialState);
        },
        addMessage: (state, action) => {
            let messages = [...state.messages];

            messages.push(action.payload.message);

            return {
                ...state,
                messages: [...messages]
            };
        },
        addHistory: (state, action) => {
            let messages = [...action.payload];

            const newArrMessages = messages.filter(object => {
                return object !== null;
            });

            return {
                ...state,
                messages: [...newArrMessages]
            };
        },
        setBadgeMessage: (state, action) => {
            return {
                ...state,
                newMessage: action.payload.newMessage
            };
        },
        clear: () => {
            return Object.assign({}, initialState);
        },
        showEmojiForm: (state, action) => {
            return {
                ...state,
                showEmoji: true,
                emoji: action.payload.emoji
            };
        },
        hideEmojiForm: (state, action) => {
            return {
                ...state,
                showEmoji: false,
                emoji: null
            };
        },
        resetBadgeMessage: (state, action) => {
            return {
                ...state,
                newMessage: false
            };
        },
        newBadgeMessage: (state, action) => {
            return {
                ...state,
                newMessage: true
            };
        }
    },
});

const { actions, reducer } = appSlice;

// export individual action creator functions
export const {
    setMessages,
    clearMessages,
    addMessage,
    setBadgeMessage,
    addHistory,
    clear,
    deleteMessageById,
    deleteMessagesByIds,
    showEmojiForm,
    hideEmojiForm,
    resetBadgeMessage,
    newBadgeMessage
} = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;