import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../utils/HelperFunctions';
import config from '../../config';

class EventEnded extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.endTimeout = setTimeout(() => {
            window.location.href = `https://${config.homeSiteDomain}/`;
        }, 10000);
    }

    componentWillUnmount() {
        if(this.endTimeout) {
            clearTimeout(this.endTimeout);
        }
    }

    render() {
        const { t } = this.props;

        return (
            <p className='end-message'>
                {t('end_message')}
            </p>
        )
    }
}

export default withTranslation()(withRouter(EventEnded));