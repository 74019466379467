import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from 'connected-react-router';
// import { reducer as voxeetReducer } from "@voxeet/react-components";
import appReducer from './app';
import firebaseReducer from './firebase';
import chatReducer from './chat';
import audienceReducer from './audience';
import hostsReducer from './hosts';
import bannedReducer from './bannedUsers';
import knocksReducer from './knocks';
import roomReducer from './room';
import deviceReducer from './device';

const reducers = (history) => combineReducers({
    router: connectRouter(history),
    app: appReducer,
    firebase: firebaseReducer,
    // voxeet: voxeetReducer,
    chat: chatReducer,
    audience: audienceReducer,
    hosts: hostsReducer,
    knocks: knocksReducer,
    room: roomReducer,
    bannedUsers: bannedReducer,
    devices: deviceReducer
});

export default reducers;