import React from 'react';
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import googleIcon from '../../resources/icons/google.svg';

const GoogleButton = (props) => {
    const loginGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            console.log('SUCCESS-loginGoogle', tokenResponse);
            if (tokenResponse && tokenResponse.access_token && props.verifyToken) {
                props.verifyToken({ token: tokenResponse.access_token, type: 'google' });
            }
        },
        onError: error => props?.handleError(error)
    });

    return (
        <div>
            <button className='btn btn-sign-in' disabled={props.disabled}
                onClick={() => loginGoogle()}>
                <img src={googleIcon} alt='google' loading='lazy' />
                <p>{props.text}</p>
            </button>
            {/* <button className='btn btn-sign-in' disabled={props.disabled}>
                <GoogleLogin
                    onSuccess={credentialResponse => {
                        console.log('SUCCESS-loginGoogle', credentialResponse);
                        if (credentialResponse && credentialResponse.credential) {
                            props.verifyToken({ token: credentialResponse.credential, type: 'google' });
                        }
                    }}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                    text={props.text}
                />
            </button> */}
        </div>
    )
}

export default GoogleButton;