import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import MuteOn from "../../../../resources/icons/room/microphone-active.svg";
import MuteOff from "../../../../resources/icons/room/microphone.svg";
import { isMobile } from "../../../../utils/HelperFunctions";

class MicrophoneButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            hover: false,
            disableBtn: false
        };
    }

    disableButton() {
        this.setState({
            disableBtn: true
        }, () => {
            this.disableBtnTimeout = setTimeout(() => {
                this.setState({
                    disableBtn: false
                });
            }, 2000);
        })
    }

    componentWillUnmount() {
        if (this.disableBtnTimeout) {
            clearTimeout(this.disableBtnTimeout);
        }
    }

    render() {
        const { isMuted, toggle, isBottomBar } = this.props;
        const { hover, isMobile, disableBtn } = this.state;
        return (
            <li
                className={classNames({ "active": isMuted }, { 'disable': disableBtn })}
                onMouseEnter={() => {
                    !isMobile && this.setState({ hover: true });
                }}
                onMouseLeave={() => {
                    !isMobile && this.setState({ hover: false });
                }}
            >
                <a
                    data-tip
                    data-for="toggle-mute"
                    className={" " + (isMuted ? "on" : "off")}
                    title={isMuted ? "Unmute" : "Mute"}
                    onClick={() => {
                        toggle();
                        this.disableButton();
                    }}
                >
                    <img src={isMuted ? MuteOn : MuteOff} />
                    {isBottomBar && (
                        <div>
                            <span>{isMuted ? "Mic off" : "Mic on"}</span>
                        </div>
                    )}
                </a>
            </li>
        );
    }
}

MicrophoneButton.propTypes = {
    toggle: PropTypes.func.isRequired,
    isMuted: PropTypes.bool.isRequired,
    isBottomBar: PropTypes.bool.isRequired
};

export default MicrophoneButton;
