import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk';
import createRootReducer from '../reducers/Reducer';
import appClient from '../utils/AppClient';
import firebaseClient from '../utils/FirebaseClient';
import fanoutClient from '../utils/FanoutClient';
import chatClient from '../utils/ChatClient';
import deviceManager from '../utils/DeviceManager';

export const history = createBrowserHistory();

const logger = createLogger({
    predicate: (getState, action) => [
        "SILENCE",
        "INCREMENT_TIMER",
        "PARTICIPANT_SPEAKING",
        "PARTICIPANT_QUALITY_UPDATED",
    ].indexOf(action.type) === -1,
    //collapsed: (getState, action) => action.type === 'FORM_CHANGE'
});

// Creating store
let middleware = [
    routerMiddleware(history), // for dispatching history actions
    thunk,
    appClient.createMiddleware(),
    chatClient.createMiddleware(),
    firebaseClient.createMiddleware(),
    fanoutClient.createMiddleware(),
    deviceManager.createMiddleware(),
];

if (process.env.NODE_ENV !== 'production') {
    middleware.push(logger);
}

export const store = configureStore({
    reducer: createRootReducer(history),
    middleware: middleware,
    devTools: true
});

// Set store
appClient.setStore(store);
chatClient.setStore(store);
firebaseClient.setStore(store);
fanoutClient.setStore(store);
deviceManager.setStore(store);