import React, { Component } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import Hangup from "../../../resources/icons/room/exit.svg";
import * as roomActions from '../../../reducers/room';

class HangUpButtonBottomBar extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { showMenu, hideMenu, exitModal } = this.props;

    if (prevProps.exitModal !== exitModal) {
      if (exitModal) {
        showMenu();
      } else {
        hideMenu();
      }
    }
  }

  render() {
    const { exitModal, showExitModal, t } = this.props;

    return (
      <li>
        <a
          data-tip
          data-for="leave"
          title={t('leave')}
          onClick={() => showExitModal(!exitModal, false)}
        >
          <img src={Hangup} alt='hangup' />
          <div>
            <span>{t('leave')}</span>
          </div>
        </a>
      </li>
    );
  }
}

HangUpButtonBottomBar.propTypes = {
  leave: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    exitModal: state.room.exitModal
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showExitModal: (exitVal, continueVal) => {
      dispatch(roomActions.showExitModal({ exitModal: exitVal, continueModal: continueVal }));
    }
  };
};

const HangUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HangUpButtonBottomBar);

export default withTranslation()(HangUpContainer);