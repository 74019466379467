import React, { Component } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../utils/HelperFunctions';
import Loader from '../Widget/Loader';
import fanoutClient from '../../utils/FanoutClient';

class VerifyCode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            verificationCode: '',
            showLoading: false,
            sendingVerificationEmail: false,
            joining: false,
            pairing: false,
            error: null,
            showError: false,
            errorMessage: null,
            terms: false,
            showTerms: false
        }

        this.handleSendAgain = this.handleSendAgain.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleSendAgain() {
        console.log('About to send code again')
        const { sendingVerificationEmail } = this.state;
        const { email, role, t } = this.props;
        if (!sendingVerificationEmail) {
            this.setState({
                sendingVerificationEmail: true
            }, () => {
                return fanoutClient.verifyEmail(email.toLowerCase(), role)
                    .then(success => {
                        if(success) {
                            this.setState({
                                sendingVerificationEmail: false
                            });
                        } else {
                            this.setState({
                                sendingVerificationEmail: false,
                                errorMessage: t('error_sending_verification_email'),
                                showError: true
                            });
                        }
                    });
            });
        }
    }

    handleClose() {
        const { close } = this.props;

        if (close)
            close();
    }

    render() {
        const { showLoading, email, t ,  } = this.props;
        const { sendingVerificationEmail, showError, errorMessage} = this.state;

        return (
            <div className='sign-in-wrapper'>
                {showError ?
                    <div className='sign-in-div'>
                        <p className='title'>{t('error')}</p>
                        <p className='text error'>{errorMessage}</p>
                        <button
                            className='btn btn-sign-in'
                            onClick={this.handleClose}
                        >
                            <p>{t('cancel')}</p>
                        </button>
                    </div> :
                    showLoading || sendingVerificationEmail ?
                        <div className='sign-in-div'>
                            <Loader
                                text={sendingVerificationEmail ? t('sending') : t('loading')}
                                dots
                            />
                        </div>
                        :
                        <div className='sign-in-div'>
                            <p className='title user-verify'>{`${t('verification_email_sent_1')} `}<span>{email}</span>{t('verification_email_sent_2')}</p>
                            <p className='title user-verify'>{`${t('click_to_finalize_registration')}`}</p>
                            <p className='text user-verify'>{`(${t('check_spam_1')}`}{` ${t('check_spam_2')})`}</p>

                            <button
                                className='btn btn-sign-in btn-blue'
                                disabled={showLoading || sendingVerificationEmail}
                                onClick={this.handleSendAgain}
                            >
                                <p>{t('send_again')}</p>
                            </button>
                            <button
                                className='btn btn-sign-in'
                                disabled={showLoading}
                                onClick={this.handleClose}
                            >
                                <p>{t('cancel')}</p>
                            </button>
                        </div>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        eventItem: state.room.eventItem
    };
};

const VerifyCodeContainer = connect(
    mapStateToProps
)(VerifyCode);

export default withTranslation()(withRouter(VerifyCodeContainer));