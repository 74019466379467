import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    menu: false,
    signIn: false,
    signUp: false,
    user: null
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        showMenu: (state, action) => {
            return Object.assign({}, state, action.payload);
        },
        showSignIn: (state, action) => {
            return Object.assign({}, state, action.payload);
        },
        setUser: (state, action) => {
            return Object.assign({}, state, action.payload);
        },
        clear: (state, action) => {
            return Object.assign({}, initialState);
        }
    },
});

const { actions, reducer } = appSlice;

// export individual action creator functions
export const {
    showMenu,
    showSignIn,
    setUser,
    clear,
} = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;