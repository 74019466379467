import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { store, history } from './store/store';
import firebaseClient from './utils/FirebaseClient';
import './style/index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-datetime/css/react-datetime.css';
import './translations/i18n';
import App from './App';
import config from './config';

const renderApp = () => {
  ReactDOM.render(
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <Fragment>
        <Provider store={store}>
          <App history={history} />
        </Provider>
      </Fragment>
    </GoogleOAuthProvider>,
    document.getElementById('root')
  );
}

const init = () => {
  firebaseClient.initFirebase().then((res) => {
    if (res) {
      renderApp();
      firebaseClient.init().then(() => {
        console.log('firebase client initialized')
      })
    }
  });
}

// eslint-disable-next-line
const error = (message) => {

  ReactDOM.render(
    <div>{message || 'Access not granted'}</div>,
    document.getElementById('root')
  );
}

let passwordProtected = false;

if (!passwordProtected) {
  console.log('About to initialize app');
  init();
} else {
  console.log('About to verify user credentials');
  const Http = new XMLHttpRequest();
  Http.withCredentials = true;
  let apiurl = "https://us-central1-streemz-net.cloudfunctions.net";
  const url = `${apiurl}/auth`;

  Http.onreadystatechange = (e) => {
    console.log('onreadystatechange', e, Http.responseText, Http.status);
    if (Http.readyState === 4) {
      console.log('response', Http.response, Http.getAllResponseHeaders());
      if (Http.status === 200)
        init();
      else
        error();
    }
  }

  Http.onerror = (e) => {
    console.log('onreadystatechange', e, Http.responseText, Http.status);
    // if (Http.readyState === 4) {
    console.log('response', Http.response, Http.getAllResponseHeaders());
    error('Could not verify user credentials');
    // }
  }
  Http.open("GET", url, true);
  Http.send();
}

if (module && module.hot) {
  module.hot.accept();
}
