import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MicrophoneButton from './Buttons/MicrophoneButton';
import VideoButton from './Buttons/VideoButton';
import KnocksContainer from '../Voxeet/KnocksButtonBottomBar';
import KnockButtonBottomBar from '../Fanout/KnockButtonBottomBar';
import AttendeesListButton from '../Voxeet/AttendeesListButton';
import StreamingButtonBottomBar from '../Voxeet/StreamingButtonBottomBar';
import HangUpButtonBottomBar from '../Voxeet/HangUpButtonBottomBar';
// import InboundVideoButtonBottomBar from './Buttons/InboundVideoButtonBottomBar';
import ScreenShareButton from './Buttons/ScreenShareButton';
import SettingsButton from './Buttons/SettingsButton';
import { isMobile } from '../../../utils/HelperFunctions';
import strmzDefault from '../../../resources/images/strmz-default.png';

class ConferenceButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBar: true
        }

        this.onShowMenu = this.onShowMenu.bind(this);
        this.onHideMenu = this.onHideMenu.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }

    componentDidMount() {
        const { isListener } = this.props;

        if (isListener) {
            if (this.barTimeout) {
                clearTimeout(this.barTimeout);
            }

            this.barTimeout = setTimeout(() => {
                const { displayAttendeesList } = this.props;
                if (!displayAttendeesList) {
                    this.setState({
                        showBar: false
                    });
                }
            }, 3000);
        }
    }

    componentDidUpdate(prevProps) {
        const { isListener, displayAttendeesList } = this.props;

        if (prevProps.isListener !== isListener) {
            if (isListener) {
                if (this.barTimeout) {
                    clearTimeout(this.barTimeout);
                }

                this.barTimeout = setTimeout(() => {
                    const { displayAttendeesList } = this.props;
                    if (!displayAttendeesList) {
                        this.setState({
                            showBar: false
                        });
                    }
                }, 3000);
            } else {
                if (this.barTimeout) {
                    clearTimeout(this.barTimeout);
                }

                this.setState({
                    showBar: true
                });
            }
        }

        if (!displayAttendeesList && ((prevProps.displayAttendeesList !== displayAttendeesList))) {
            this.onHideMenu();
        }
    }

    onShowMenu() {
        const { isListener } = this.props;

        if (isListener) {
            if (this.barTimeout) {
                clearTimeout(this.barTimeout);
            }

            this.setState({
                showBar: true
            });
        }
    }

    onHideMenu() {
        const { isListener } = this.props;

        if (isListener) {
            if (this.barTimeout) {
                clearTimeout(this.barTimeout);
            }

            this.barTimeout = setTimeout(() => {
                const { displayAttendeesList } = this.props;
                if (!displayAttendeesList) {
                    this.setState({
                        showBar: false
                    });
                }
            }, 2000);
        }
    }

    onMouseMove() {
        const { isListener } = this.props;

        if (isListener) {
            this.setState({
                showBar: true
            });

            if (this.barTimeout) {
                clearTimeout(this.barTimeout);
            }

            this.barTimeout = setTimeout(() => {
                const { displayAttendeesList } = this.props;
                if (!displayAttendeesList) {
                    this.setState({
                        showBar: false
                    });
                }
            }, 3000);
        }
    }

    render() {
        const {
            showBar
        } = this.state;
        const {
            isMuted,
            isAdmin,
            isListener,
            handleMuteAudio,
            role,
            streaming,
            videoEnabled,
            handleMuteVideo,
            getCallState,
            toggleList,
            displayAttendeesList,
            toggleSettings,
            attendeesSettingsOpened,
            newMessage,
            endConference,
            eventId,
            eventItem,
            knockId,
            isAnonymous,
            toggleScreenShare,
            toggleVideoPresentation,
            selfScreenShare,
            selfVideoPresentation,
            muteRemoteVideosForMe
        } = this.props;
        return (
            <Fragment>
                {!showBar ?
                    <div
                        className='hide-button-overlay'
                        onMouseMove={this.onMouseMove}
                    />
                    : null
                }
                <div
                    className={classNames("vxt-bottom-bar custom-actions-buttons", { 'show': showBar }, { 'hide': !showBar }, { 'transparent': isListener })}
                    onMouseOver={this.onShowMenu}
                    onMouseLeave={this.onHideMenu}
                >
                    <div className="vxt-bottom-bar-actions">
                        <ul className="controls-left">
                            {eventItem ?
                                <div className='bottom-bar-details'>
                                    <img src={eventItem.logo ? eventItem.logo : strmzDefault} alt='img' />
                                    <p>{eventItem.title ? eventItem.title : ''}</p>
                                </div>
                                : null
                            }
                        </ul>
                        <ul className="controls-center">
                            {/* {isListener && (
                                <InboundVideoButtonBottomBar
                                    muteRemoteVideosForMe={muteRemoteVideosForMe}
                                />
                            )} */}
                            {!isListener && !isMobile() && (
                                <ScreenShareButton
                                    shareActions={["screenshare", "filepresentation", "videopresentation"]}
                                    isBottomBar={true}
                                    screenShareEnabled={selfScreenShare}
                                    filePresentationEnabled={false}
                                    videoPresentationEnabled={selfVideoPresentation}
                                    currentUserScreenShare={selfScreenShare}
                                    currentUserFilePresentation={false}
                                    currentUserVideoPresentation={selfVideoPresentation}
                                    convertFilePresentation={(e) => console.log('convert file presentation', e)}
                                    toggle={toggleScreenShare}
                                    toggleVideoPresentation={toggleVideoPresentation}
                                />)
                            }
                            {!isListener && (
                                <MicrophoneButton
                                    isMuted={isMuted}
                                    toggle={handleMuteAudio}
                                    isBottomBar={true}
                                />)}
                            {(role && (role !== 'moderator' || (role === 'moderator' && !streaming))) && !isListener && (
                                <VideoButton
                                    videoEnabled={videoEnabled}
                                    toggle={handleMuteVideo}
                                    isBottomBar={true}
                                />
                            )}
                            <HangUpButtonBottomBar
                                leave={endConference}
                                getCallState={getCallState}
                                eventId={eventId}
                                role={role}
                                hideMenu={this.onHideMenu}
                                showMenu={this.onShowMenu}
                            />
                            {!isAdmin && isListener && (
                                <KnockButtonBottomBar
                                    getCallState={getCallState}
                                />
                            )}
                            {isAdmin && (
                                <KnocksContainer
                                    getCallState={getCallState}
                                />
                            )}
                        </ul>
                        <ul className="controls-right">
                            <AttendeesListButton
                                toggle={toggleList}
                                isBottomBar={true}
                                isAdmin={isAdmin}
                                role={role}
                                isOpen={displayAttendeesList}
                                newMessage={newMessage}
                            />
                            {isAdmin && !isListener && (
                                <StreamingButtonBottomBar
                                    eventItem={eventItem}
                                    getCallState={getCallState}
                                    role={role}
                                />
                            )}
                            {!isListener && (
                                <SettingsButton
                                    attendeesSettingsOpened={attendeesSettingsOpened}
                                    toggle={toggleSettings}
                                    isBottomBar={true}
                                    isListener={isListener}
                                />
                            )}
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        streaming: state.room.streaming,
        newMessage: state.chat.newMessage
    };
};

const ConferenceButtonsContainer = connect(
    mapStateToProps,
    null // mapDispatchToProps
)(ConferenceButtons);

export default ConferenceButtonsContainer;