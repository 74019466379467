import React, { Component } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    withRouter,
    getParticipantsTerms,
    setParticipantsTerms
} from '../../utils/HelperFunctions';
import Loader from '../Widget/Loader';
import SignInMail from "./SignInMail";
import firebaseClient from '../../utils/FirebaseClient';
import googleIcon from '../../resources/icons/google.svg';
import appleIcon from '../../resources/icons/apple.svg';
import microsoftIcon from '../../resources/icons/microsoft.svg';
import mailIcon from '../../resources/icons/mail-white.svg';
import config from '../../config';
import GoogleButton from "./GoogleButton";
import AppleButton from "./AppleButton";

class SignIn extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showLoading: false,
            terms: false,
            showTerms: false,
            pairing: false,
            error: null,
            showError: false,
            showPairing: false,
            loaderText: '',
            signInMail: false
        }
        this.handleSetTerms = this.handleSetTerms.bind(this);
        this.setProvider = this.setProvider.bind(this);
        this.setCutomProvider = this.setCutomProvider.bind(this);
        this.handleButtonError = this.handleButtonError.bind(this);
        this.reset = this.reset.bind(this);
        this.startPairing = this.startPairing.bind(this);
        this.handleShowMailForm = this.handleShowMailForm.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;

        if (location && location.pathname && (typeof location.pathname === 'string' || location.pathname instanceof String)) {
            let splitPathname = location.pathname.split("/");

            if (splitPathname && splitPathname.length) {
                const storageTerms = getParticipantsTerms(splitPathname[splitPathname.length - 1]);
                this.setState({ terms: storageTerms });
                this.setState({ showTerms: !storageTerms });
            }
        }
    };

    setProvider(provider) {
        const { t } = this.props;

        if (provider) {
            this.setState({
                showLoading: true,
                showError: false,
                showPairing: false,
                loaderText: ''
            }, () => {
                firebaseClient.logIn(provider).catch(error => {
                    if (error.code === 'auth/account-exists-with-different-credential') {
                        this.setState({
                            showPairing: true,
                            showError: false,
                            showLoading: false,
                            error,
                            loaderText: ''
                        });
                    } else if (error.code === 'auth/popup-closed-by-user') {
                        this.setState({
                            showPairing: false,
                            showError: false,
                            showLoading: true,
                            error,
                            loaderText: t('login_cancelled')
                        }, () => {
                            setTimeout(() => {
                                this.reset();
                            }, 2000);
                        });
                    } else {
                        this.setState({
                            showError: true,
                            showPairing: false,
                            showLoading: false,
                            error,
                            loaderText: ''
                        });
                    }
                });
            });
        }
    }

    setCutomProvider(data) {
        const { t } = this.props;

        if (data) {
            this.setState({
                showLoading: true,
                showError: false,
                showPairing: false,
                loaderText: ''
            }, () => {
                firebaseClient.verifyToken(data).catch(error => {
                    if (error.code === 'auth/account-exists-with-different-credential') {
                        this.setState({
                            showPairing: true,
                            showError: false,
                            showLoading: false,
                            error,
                            loaderText: ''
                        });
                    } else if (error.code === 'auth/popup-closed-by-user') {
                        this.setState({
                            showPairing: false,
                            showError: false,
                            showLoading: true,
                            error,
                            loaderText: t('login_cancelled')
                        }, () => {
                            setTimeout(() => {
                                this.reset();
                            }, 2000);
                        });
                    } else {
                        this.setState({
                            showError: true,
                            showPairing: false,
                            showLoading: false,
                            error,
                            loaderText: ''
                        });
                    }
                });
            });
        }
    }

    startPairing() {
        if (this.state.error) {
            this.setState({
                showLoading: true,
                showError: false,
                showPairing: false,
                loaderText: ''
            }, () => {
                firebaseClient.pairAccounts(this.state.error).catch(error => {
                    this.setState({
                        showError: true,
                        showPairing: false,
                        showLoading: false,
                        error,
                        loaderText: ''
                    });
                });
            });
        } else {
            console.error('Could not find pending credentials');
        }
    }

    handleSetTerms(value) {
        const { location, eventItem } = this.props;

        if (location && location.pathname && (typeof location.pathname === 'string' || location.pathname instanceof String)) {
            let splitPathname = location.pathname.split("/");

            if (splitPathname && splitPathname.length) {
                this.setState({ terms: value });
                setParticipantsTerms(splitPathname[splitPathname.length - 1], value, eventItem && eventItem.startDate ? eventItem.startDate : null);
            }
        }
    }

    reset() {
        this.setState({
            showError: false,
            signInMail: false,
            showPairing: false,
            showLoading: false,
            error: null,
            loaderText: ''
        });
    }

    handleShowMailForm() {
        this.setState({
            signInMail: true
        });
    }

    handleButtonError(err) {
        const { t } = this.props;
        if (err.error === 'account-exists-with-different-credential' || err.code === 'auth/account-exists-with-different-credential') {
            this.setState({
                showPairing: true,
                showError: false,
                showLoading: false,
                error: err,
                loaderText: ''
            });
        } else if (err.error === 'popup_closed_by_user' || err.code === 'auth/popup-closed-by-user') {
            this.setState({
                showPairing: false,
                showError: false,
                showLoading: true,
                error: err,
                loaderText: t('login_cancelled')
            }, () => {
                setTimeout(() => {
                    this.reset();
                }, 2000);
            });
        } else {
            this.setState({
                showError: true,
                showPairing: false,
                showLoading: false,
                error: err,
                loaderText: ''
            });
        }
    }

    render() {
        const { showLoading, terms, showTerms, showError, showPairing, loaderText, signInMail } = this.state;
        const { t } = this.props;

        return (
            <div className='sign-in-wrapper'>
                {signInMail ?
                    <SignInMail
                        close={this.reset}
                    />
                    : showError ?
                        <div className='sign-in-div'>
                            <p className='title'>{t('error')}</p>
                            <p className='text error'>{t('some_error')}</p>
                            <button
                                className='btn btn-sign-in'
                                onClick={this.reset}
                            >
                                <p>{t('cancel')}</p>
                            </button>
                        </div> :
                        showLoading ?
                            <div className='sign-in-div'>
                                <Loader
                                    text={loaderText ? loaderText : t('loading')}
                                    dots={!loaderText}
                                />
                            </div> :
                            showPairing ?
                                <div className='sign-in-div'>
                                    <p className='title'>{t('pairing')}</p>
                                    <p className='text'>
                                        {t('different_provider')}
                                        <br />
                                        {t('like_pair')}
                                    </p>
                                    <button
                                        className='btn btn-sign-in btn-blue'
                                        onClick={this.startPairing}
                                    >
                                        <p>{t('pair')}</p>
                                    </button>
                                    <button
                                        className='btn btn-sign-in'
                                        onClick={this.reset}
                                    >
                                        <p>{t('cancel')}</p>
                                    </button>
                                </div>
                                :
                                <div className='sign-in-div'>
                                    <p className='title'>{t('sign_in')}</p>
                                    {showTerms ?
                                        <div className='accept-terms'>
                                            <input
                                                type='checkbox'
                                                checked={terms}
                                                onChange={(e) => this.handleSetTerms(e.target.checked)}
                                            />
                                            <p>
                                                {t('agree_2')}
                                                <a
                                                    href={`https://${config.homeSiteDomain}/participant-terms-of-use`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t('event_participant_terms_of_use')}
                                                </a>
                                                .
                                            </p>
                                        </div>
                                        : null
                                    }
                                    <GoogleButton
                                        text={t('google_sign')}
                                        disabled={showLoading || !terms}
                                        verifyToken={this.setCutomProvider}
                                        handleError={this.handleButtonError}
                                    />
                                    {/* <button className='btn btn-sign-in' disabled={showLoading || !terms}
                                        onClick={() => this.setProvider('google')}>
                                        <img src={googleIcon} alt='google' loading='lazy' />
                                        <p>{t('google_sign')}</p>
                                    </button> */}
                                    <AppleButton
                                        text={t('apple_sign')}
                                        disabled={showLoading || !terms}
                                        verifyToken={this.setCutomProvider}
                                        handleError={this.handleButtonError}
                                    />
                                    {/* <button className='btn btn-sign-in' disabled={showLoading || !terms}
                                        onClick={() => this.setProvider('apple')}>
                                        <img src={appleIcon} alt='apple' loading='lazy' />
                                        <p>{t('apple_sign')}</p>
                                    </button> */}
                                    <button className='btn btn-sign-in' disabled={showLoading || !terms}
                                        onClick={() => this.setProvider('microsoft')}>
                                        <img src={microsoftIcon} alt='microsoft' loading='lazy' />
                                        <p>{t('microsoft_sign')}</p>
                                    </button>
                                    <button className='btn btn-sign-in' disabled={showLoading || !terms}
                                        onClick={this.handleShowMailForm}>
                                        <img src={mailIcon} alt='mail' loading='lazy' />
                                        <p>{t('mail_sign')}</p>
                                    </button>
                                </div>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        active: state.app.active,
        eventItem: state.room.eventItem
    };
};

const SignInContainer = connect(
    mapStateToProps
)(SignIn);

export default withTranslation()(withRouter(SignInContainer));
