import React, { useEffect } from 'react';
import Loader from "../Widget/Loader";
import config from '../../config';

function NotFound() {

    useEffect(() => {
        window.location.href = `https://${config.homeSiteDomain}/`;
    });

    return <Loader />;
}

export default NotFound;