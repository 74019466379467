import React, { Component } from "react";
import { connect } from 'react-redux';
import AutoSizer from "react-virtualized-auto-sizer";
import { Rnd } from "react-rnd";
import Roster from './Roster';
import FloatingEmoji from '../FloatingEmoji';
import NoStreamingText from "./NoStreamingText";
import RecordingStatus from "./RecordingStatus";
import AttendeesChat from './AttendeesChat';
import RoomMessage from "../../Room/RoomMessage";
import * as chatActions from "../../../reducers/chat";

class ResizableRoster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            runningAnimation: false,
            attendeesListOpened: props.attendeesListOpened,
            show: 'chat',
            width: null,
            height: null,
            rndWidth: 350,
            rndHeight: 400,
            rndX: 0,
            rndY: 0,
            side: 'mount'
        };

        this.handleResize = this.handleResize.bind(this);
        this.dragStop = this.dragStop.bind(this);
        this.rndResize = this.rndResize.bind(this);
    }

    getChatPosition() {
        const { width, height } = this.state;

        let chatPosition = window.localStorage.getItem('chatPosition');

        if (chatPosition) {
            try {
                chatPosition = JSON.parse(chatPosition);

                let storageX, storageY, storageWidth, storageHeight;

                storageWidth = chatPosition.side ? 350 : chatPosition.rndWidth === 100 ? '100%' : parseInt(chatPosition.rndWidth * (width / 100)) || 0;
                storageHeight = chatPosition.side || chatPosition.rndHeight === 100 ? '100%' : parseInt(chatPosition.rndHeight * (height / 100)) || 0;
                storageY = parseInt(chatPosition.rndY * (height / 100)) || 0;
                storageX = chatPosition.side && chatPosition.side === 'left' ? 0 : chatPosition.side && chatPosition.side === 'right' ? width - storageWidth : parseInt(chatPosition.rndX * (width / 100)) || 0;

                this.setState({
                    rndX: storageX,
                    rndY: storageY,
                    rndHeight: storageHeight,
                    rndWidth: storageWidth,
                    side: chatPosition.side || ''
                });
            } catch (e) {
                console.error(e);
            }
        } else {
            this.setState({
                rndX: width / 4,
                rndY: height - 285,
                rndHeight: 200,
                rndWidth: width / 2,
                side: ''
            });
        }
    }

    saveChatPosition() {
        const { rndX, rndY, rndHeight, rndWidth, width, height, side } = this.state;

        if (!side || side && side !== 'mount') {
            const position = {
                rndX: parseFloat(rndX / (width / 100)).toFixed(2) || 0,
                rndY: parseFloat(rndY / (height / 100)).toFixed(2) || 0,
                rndHeight: rndHeight === '100%' ? '100.00' : parseFloat(rndHeight / (height / 100)).toFixed(2) || 0,
                rndWidth: rndWidth === '100%' ? '100.00' : parseFloat(rndWidth / (width / 100)).toFixed(2) || 0,
                side: side || ''
            }
            window.localStorage.setItem('chatPosition', JSON.stringify(position));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { side, width, show } = this.state;
        const { attendeesListOpened, messages, newMessage, resetBadgeMessage, newBadgeMessage } = this.props;
        // Check if it should stop animation
        if (prevProps.attendeesListOpened == true && attendeesListOpened == false) {
            setTimeout(() => {
                this.setState({ runningAnimation: false });
            }, 250);
        }

        if (prevState.width !== width && width <= 1200 && width < prevState.width) {
            this.setState({
                rndX: 0,
                rndY: 0,
                rndHeight: '100%',
                rndWidth: 350
            }, () => {
                this.saveChatPosition();
            });
        }

        if (prevProps.attendeesListOpened !== attendeesListOpened) {
            this.handleSetRosterId(window.innerWidth);

            if (attendeesListOpened) {
                const { width, height, side } = this.state;

                if (width && height && side && side === 'mount') {
                    this.getChatPosition();
                }
            } else if (side) {
                this.setState({
                    side: ''
                }, () => {
                    this.saveChatPosition();
                });
            }
        }

        if (messages && messages.length && prevProps.messages && prevProps.messages.length !== messages.length && prevProps.messages.length < messages.length && !newMessage && newBadgeMessage && ((!attendeesListOpened) || (attendeesListOpened && show && show !== 'chat' && width && width <= 1200))) {
            newBadgeMessage();
        }
        if (((((attendeesListOpened && width && width > 1200) || (attendeesListOpened && show && show === 'chat' && width && width <= 1200)) && attendeesListOpened !== prevProps.attendeesListOpened) || (show && show === 'chat' && show !== prevState.show)) && newMessage && resetBadgeMessage) {
            resetBadgeMessage();
        }
    }

    handleSetRosterId(width) {
        let sidebar = window.document.getElementsByClassName('sidebar-container');
        // console.log('SIDEBAR', sidebar);

        if (sidebar && sidebar[0] && this.props.attendeesListOpened && width > 1200 && this.state.show === 'chat') {
            sidebar[0].id = 'roster-closed';
        } else if (sidebar && sidebar[0]) {
            sidebar[0].id = '';
        }
    }

    handleResize(event) {
        const { rndX, rndY, rndHeight, rndWidth, width, height, side } = this.state;

        this.setState({
            width: event.width,
            height: event.height
        });

        if (this.props.attendeesListOpened && event.width) {
            this.handleSetRosterId(event.width);

            const position = {
                rndX: parseFloat(rndX / (width / 100)) || 0,
                rndY: parseFloat(rndY / (height / 100)) || 0,
                rndHeight: rndHeight === '100%' ? 100 : parseFloat(rndHeight / (height / 100)) || 0,
                rndWidth: rndWidth === '100%' ? 100 : parseFloat(rndWidth / (width / 100)) || 0,
                side: side || ''
            }

            this.setState({
                rndX: parseFloat(position.rndX * (event.width / 100)) || 0,
                rndY: parseFloat(position.rndY * (event.height / 100)) || 0,
                rndHeight: rndHeight === '100%' ? rndHeight : parseFloat(position.rndHeight * (event.height / 100)) || 0,
                rndWidth: rndWidth === '100%' ? rndWidth : parseFloat(position.rndWidth * (event.width / 100)) || 0,
                side: position.side || ''
            }, () => {
                this.handleSetSide();
                this.saveChatPosition();
            });
        }
    }

    setShowState(value, width) {
        const { side, rndX, rndWidth } = this.state;

        let val = width > 1200 ? value === 'list' && this.state.show === 'list' ? 'chat' : 'list' : value;
        this.setState({
            show: val
        }, () => {
            this.handleSetRosterId(width);
            if (val === 'list' && ((side && side === 'right') || (((rndX + rndWidth) < width + 20) && ((rndX + rndWidth) > width - 370)))) {
                this.setState({
                    rndX: width - 700,
                    side: 'right',
                    rndY: 0,
                    rndHeight: '100%',
                    rndWidth: 350
                }, () => {
                    this.saveChatPosition();
                });
            } else if (val === 'chat' && side && side === 'right') {
                this.setState({
                    rndX: width - 350,
                    rndY: 0,
                    rndHeight: '100%',
                    rndWidth: 350
                }, () => {
                    this.saveChatPosition();
                });
            }
        });
    }

    dragStop(e, d) {
        this.setState({
            rndX: d.x, rndY: d.y
        }, () => {
            this.handleSetSide();
            this.saveChatPosition();
        });
    }

    handleSetSide() {
        const { rndWidth, rndX, width, side, show } = this.state;
        if (rndX === 0 || rndX < 20) {
            this.setState({
                rndY: 0,
                rndX: 0,
                rndHeight: '100%',
                rndWidth: 350,
                side: 'left'
            }, () => {
                this.saveChatPosition();
            });
        } else if (((rndX + rndWidth) === width) || (((rndX + rndWidth) < width + 20) && ((rndX + rndWidth) > width - 20))) {
            this.setState({
                rndY: 0,
                rndX: width - 350,
                rndHeight: '100%',
                rndWidth: 350,
                side: 'right'
            }, () => {
                this.saveChatPosition();
            });
        } else if (((rndX + rndWidth) < width + 10) && ((rndX + rndWidth) > width - 370) && show && show === 'list') {
            this.setState({
                rndY: 0,
                rndX: width - 700,
                rndHeight: '100%',
                rndWidth: 350,
                side: 'right'
            }, () => {
                this.saveChatPosition();
            });
        } else if (side) {
            this.setState({
                side: ''
            }, () => {
                this.saveChatPosition();
            });
        }
    }

    rndResize(e, direction, ref, delta, position) {
        this.setState({
            rndWidth: ref.offsetWidth,
            rndHeight: ref.offsetHeight,
            rndX: position.x,
            rndY: position.y
        }, () => {
            const { hideEmoji } = this.props;

            if (hideEmoji) {
                hideEmoji();
            }

            this.saveChatPosition();
        });
    }

    render() {
        const { show, rndHeight, rndWidth, rndX, rndY } = this.state;
        const { attendeesListOpened, role, sendMessage, getCallState, eventItem, showEmoji, emoji, isListener, isAnonymous } = this.props;

        return (
            <AutoSizer onResize={this.handleResize}>
                {({ height, width }) => {
                    if (width && width > 1200) {
                        return (
                            <div className='resizable-wrapper'>
                                <div
                                    className={
                                        show && show === 'list'
                                            ? this.state.runningAnimation
                                                ? "attendees-list attendees-list-out"
                                                : attendeesListOpened
                                                    ? "attendees-list"
                                                    : "attendees-list-hidden"
                                            : "attendees-list-hidden"
                                    }
                                >
                                    <Roster
                                        eventItem={eventItem}
                                        role={role}
                                        roster={width < 1200}
                                        showState={show}
                                        setShowState={(value) => this.setShowState(value, width)}
                                    />
                                </div>
                                <Rnd
                                    className={`${this.state.runningAnimation
                                        ? "attendees-chat attendees-chat-out"
                                        : attendeesListOpened
                                            ? 'attendees-chat'
                                            : "attendees-chat-hidden"
                                        } draggable-chat`}
                                    bounds='parent'
                                    cancel='.container-input-chat, .item-name, .more-menu, .item-message'
                                    size={{ width: rndWidth, height: rndHeight }}
                                    position={{ x: rndX, y: rndY }}
                                    onDragStop={this.dragStop}
                                    onResize={this.rndResize}
                                    minWidth={350}
                                    minHeight={200}
                                >
                                    <AttendeesChat
                                        attendeesChatOpened={attendeesListOpened}
                                        roster
                                        showState={show}
                                        width={width}
                                        setShowState={(value) => this.setShowState(value, width)}
                                        draggable={true}
                                        role={role}
                                        sendMessage={sendMessage}
                                        getCallState={getCallState}
                                        isListener={isListener}
                                        isAnonymous={isAnonymous}
                                    />
                                </Rnd>
                                {showEmoji ?
                                    <FloatingEmoji
                                        emoji={emoji}
                                    />
                                    : null}
                                <NoStreamingText
                                    eventItem={eventItem}
                                    role={role}
                                />
                                {!isListener ?
                                    <RecordingStatus />
                                    : null}
                                <RoomMessage />
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                {show === 'chat' ? <div
                                    className={
                                        this.state.runningAnimation
                                            ? "attendees-chat attendees-chat-out"
                                            : attendeesListOpened
                                                ? `attendees-chat ${width < 1200 ? 'resizable-roster-chat-hidden' : 'resizable-roster-chat'}`
                                                : "attendees-chat-hidden"
                                    }
                                >
                                    <AttendeesChat
                                        attendeesChatOpened={attendeesListOpened}
                                        roster
                                        showState={show}
                                        setShowState={(value) => this.setShowState(value, width)}
                                        role={role}
                                        sendMessage={sendMessage}
                                        getCallState={getCallState}
                                        isListener={isListener}
                                        isAnonymous={isAnonymous}
                                    />
                                </div>
                                    :
                                    <div
                                        className={
                                            this.state.runningAnimation
                                                ? "attendees-list attendees-list-out"
                                                : attendeesListOpened
                                                    ? `attendees-list ${width < 1200 ? 'resizable-roster-list-hidden' : 'resizable-roster-list'}`
                                                    : "attendees-list-hidden"
                                        }
                                    >
                                        <Roster
                                            eventItem={eventItem}
                                            role={role}
                                            roster={width < 1200}
                                            showState={show}
                                            setShowState={(value) => this.setShowState(value, width)}
                                        />
                                    </div>
                                }
                                {showEmoji ?
                                    <FloatingEmoji
                                        emoji={emoji}
                                    />
                                    : null}
                                <NoStreamingText
                                    eventItem={eventItem}
                                    role={role}
                                />
                                {!isListener ?
                                    <RecordingStatus />
                                    : null}
                                <RoomMessage />
                            </div>
                        )
                    }
                }}
            </AutoSizer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        audienceList: state.firebase.audienceList,
        messages: state.chat.messages,
        newMessage: state.chat.newMessage,
        showEmoji: state.chat.showEmoji,
        emoji: state.chat.emoji
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        newBadgeMessage: () => {
            dispatch(chatActions.newBadgeMessage());
        },
        resetBadgeMessage: () => {
            dispatch(chatActions.resetBadgeMessage());
        },
        hideEmoji: () => {
            dispatch(chatActions.hideEmojiForm());
        }
    };
};

const ResizableRosterContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResizableRoster);

export default ResizableRosterContainer;