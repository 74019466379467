import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import { withTranslation } from 'react-i18next';
import fanoutClient from "../../../utils/FanoutClient";
import KnockImg from "../../../resources/icons/room/hand.svg";
import plusIcon from "../../../resources/icons/+.svg";
import xIcon from "../../../resources/icons/x.svg";
import * as roomActions from '../../../reducers/room';

class KnocksButtonBottomBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            knockArray: {},
            participantsArray: {},
            menu: false,
            runningAnimation: false,
            inviteInput: '',
            liveInput: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let stateUpdate = {
            showKnocks: nextProps.showKnocks,
            menu: nextProps.showKnocks
        };
        // Check if it should run animation
        if (prevState.showKnocks === false && nextProps.showKnocks === true) {
            stateUpdate.runningAnimation = true;
        } else if (prevState.showKnocks === true && nextProps.showKnocks === false) {
            stateUpdate.runningAnimation = false;
        }
        return stateUpdate;
    }

    getListKnocks(knockList, accepted) {
        let filterJSON = {};

        Object.entries(knockList).filter(([key, value]) => {
            if (accepted && value.accepted) {
                filterJSON[key] = value;
            } else if (!accepted && !value.accepted) {
                filterJSON[key] = value;
            }

            return key;
        });

        return filterJSON;
    }

    componentDidMount() {
        const { inviteInput, liveInput } = this.state;
        const { knockList } = this.props;

        if (knockList && Object.keys(knockList).length && !inviteInput) {
            this.setState({
                knockArray: this.getListKnocks(knockList, false)
            });
        }

        if (knockList && Object.keys(knockList).length && !liveInput) {
            this.setState({
                participantsArray: this.getListKnocks(knockList, true)
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { inviteInput, liveInput } = this.state;
        const { knockList } = this.props;

        if (JSON.stringify(knockList) !== JSON.stringify(prevProps.knockList) && !inviteInput) {
            this.setState({
                knockArray: this.getListKnocks(knockList, false)
            });
        }

        if (JSON.stringify(knockList) !== JSON.stringify(prevProps.knockList) && !liveInput) {
            this.setState({
                participantsArray: this.getListKnocks(knockList, true)
            });
        }
    }

    handleChangeInviteInput(event) {
        const { knockList } = this.props;
        const inputValue = event.target.value;

        this.setState({
            inviteInput: inputValue
        });

        let array = this.getListKnocks(knockList, false);

        if (array && Object.keys(array).length) {
            let filterJSON = {};
            let input = inputValue;
            Object.keys(array).filter((key) => {
                let itemName = array[key].name;
                itemName = itemName.toLowerCase();
                input = input.toLowerCase();
                if (itemName.indexOf(input) !== -1) {
                    filterJSON[key] = array[key];
                }
                return array[key];
            });
            this.setState({
                knockArray: filterJSON
            })
        }
    }

    handleChangeLiveInput(event) {
        const { knockList } = this.props;
        const inputValue = event.target.value;

        this.setState({
            liveInput: inputValue
        });

        let array = this.getListKnocks(knockList, true);

        if (array && Object.keys(array).length) {
            let filterJSON = {};
            let input = inputValue;
            Object.keys(array).filter((key) => {
                let itemName = array[key].name;
                itemName = itemName.toLowerCase();
                input = input.toLowerCase();
                if (itemName.indexOf(input) !== -1) {
                    filterJSON[key] = array[key];
                }
                return array[key];
            });
            this.setState({
                participantsArray: filterJSON
            });
        }
    }

    handleShowKnocks() {
        const { showKnocksMenu, showKnocks } = this.props;
        if (showKnocksMenu) {
            showKnocksMenu(!showKnocks);
        }
    }

    handleChangeInviteUser(rid) {
        const { getCallState } = this.props;
        if (rid && getCallState) {
            fanoutClient.sendKnockGranted(rid, getCallState());
        }
    }

    handleChangeSwitchUser(rid) {
        const { getCallState } = this.props;
        if (rid && getCallState) {
            fanoutClient.sendKnockRevoked(rid, getCallState());
        }
    }

    render() {
        const { knockArray, participantsArray, menu, runningAnimation, inviteInput, liveInput } = this.state;
        const { t } = this.props;

        return (
            <li className={classNames("toggle-knocks", { 'active': (menu) || (knockArray && Object.keys(knockArray).length) || (participantsArray && Object.keys(participantsArray).length) }, { "menu-animation": runningAnimation })}>
                {menu &&
                    <div className="bubble-tip knock-menu">
                        <a
                            className="icon-close"
                            title={t('close')}
                            onClick={this.handleShowKnocks.bind(this)}
                        />
                        <span className='title'>{t('knock_panel')}</span>
                        <div className='knocks-wrapper'>
                            <div className='knocks-div'>
                                <p className='title-text'>{t('knocks_queue')}</p>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    className="invite-input"
                                    placeholder={t('start_typing_search')}
                                    value={inviteInput}
                                    onChange={this.handleChangeInviteInput.bind(this)}
                                />
                                <div className={classNames('invite-users', { 'small': false })}>
                                    {knockArray && Object.keys(knockArray).length ?
                                        Object.keys(knockArray).map((key, index) => {
                                            if (knockArray[key] && (knockArray[key].name || knockArray[key].username)) {
                                                return (
                                                    <button
                                                        key={index}
                                                        className='btn btn-knocks-blue'
                                                        onClick={() => this.handleChangeInviteUser(knockArray[key].rid)}
                                                    >
                                                        <span className="item-name">
                                                            {knockArray[key].name || knockArray[key].username}
                                                        </span>
                                                        <img src={plusIcon} alt='+' loading='lazy' />
                                                    </button>
                                                )
                                            } else return null;
                                        })
                                        : inviteInput ?
                                            <p className="no-user-text">{t('no_filtered_users')}</p>
                                            :
                                            <p className="no-user-text">{t('enter_search_text')}</p>
                                    }
                                </div>
                            </div>
                            <div className='knocks-div'>
                                <p className='title-text'>{t('guests')}</p>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    className="invite-input"
                                    placeholder={t('start_typing_search')}
                                    value={liveInput}
                                    onChange={this.handleChangeLiveInput.bind(this)}
                                />
                                <div className={classNames('invite-users', { 'small': false })}>
                                    {participantsArray && Object.keys(participantsArray).length ?
                                        Object.keys(participantsArray).map((key, index) => {
                                            if (participantsArray[key] && (participantsArray[key].name || participantsArray[key].username)) {
                                                return (
                                                    <button
                                                        key={index}
                                                        className='btn btn-knocks-white'
                                                        onClick={() => this.handleChangeSwitchUser(participantsArray[key].rid)}
                                                    >

                                                        <span className="item-name">
                                                            {participantsArray[key].name || participantsArray[key].username}
                                                        </span>
                                                        <img src={xIcon} alt='x' loading='lazy' />
                                                    </button>
                                                )
                                            } else return null;
                                        })
                                        : liveInput ?
                                            <p className="no-user-text">{t('no_filtered_users')}</p>
                                            :
                                            <p className="no-user-text">{t('enter_search_text')}</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="anchor-popup" />
                    </div>}
                <a
                    data-tip
                    data-for="knocks"
                    title={t('knocks')}
                    onClick={this.handleShowKnocks.bind(this)}
                >
                    {knockArray && Object.keys(knockArray).length ? <span className="attendees-number knockUsers">{Object.keys(knockArray).length}</span> : null}
                    {participantsArray && Object.keys(participantsArray).length ? <span className="attendees-number activeUsers">{Object.keys(participantsArray).length}</span> : null}
                    <img src={KnockImg} alt='knock' />
                    <div>
                        <span>{t('knocks')}</span>
                    </div>
                </a>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showKnocks: state.room.showKnocks,
        knockList: state.knocks
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showKnocksMenu: (value) => {
            dispatch(roomActions.showKnocks({ showKnocks: value }));
        }
    };
};

const KnocksContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(KnocksButtonBottomBar);

export default withTranslation()(KnocksContainer);
