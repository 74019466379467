import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import ClickOutside from '../../Widget/ClickOutside';
import fanoutClient from "../../../utils/FanoutClient";
import userPlaceholder from "../../../resources/assets/user-placeholder.png";
import moreIcon from '../../../resources/assets/more-vertical.svg';
import chatOff from "../../../resources/icons/room/chat.svg";
import chatOn from "../../../resources/icons/room/chat-active.svg";
import listOff from "../../../resources/assets/Viewers.svg";
import listOn from "../../../resources/assets/Viewers-Active.svg";
import { validLink } from '../../../utils/HelperFunctions';

class Roster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreId: null
        };
        this.openChat = this.openChat.bind(this);
        this.openRoster = this.openRoster.bind(this);
    }

    openChat() {
        const { setShowState } = this.props;
        if (setShowState) {
            setShowState('chat');
        }
    }

    openRoster() {
        const { setShowState } = this.props;
        if (setShowState) {
            setShowState('list');
        }
    }

    componentDidUpdate(prevProps) {
        const { moreId } = this.state;
        const { attendeesListOpened, showState } = this.props;

        if (moreId && ((!attendeesListOpened && prevProps.attendeesListOpened !== attendeesListOpened) || (showState && showState === 'chat' && prevProps.showState !== showState))) {
            this.setState({
                moreId: null
            });
        }

        if (prevProps.attendeesListOpened && !attendeesListOpened) {
            setTimeout(() => {
                this.setState({ runningAnimation: false });
            }, 250);
        }

        if (prevProps.attendeesListOpened !== attendeesListOpened) {
            this.handleSetRosterId();
        }
    }

    componentWillUnmount() {
        const { moreId } = this.state;

        if (moreId) {
            this.setState({
                moreId: null
            });
        }
    }

    banUser(uid) {
        if (uid) {
            this.setState({
                moreId: null
            }, () => {
                console.log('ban the user', uid);
                fanoutClient.banUser(uid, true);
            })
        }
    }

    unBanUser(uid) {
        if (uid) {
            this.setState({
                moreId: null
            }, () => {
                console.log('unban the user', uid);
                fanoutClient.banUser(uid, false);
            })
        }
    }

    render() {
        const { moreId } = this.state;
        const { hosts, audience, bannedUsers, role, roster, showState } = this.props;

        return (
            <div>
                {roster ? <div className="attendees-list-header">
                    <div className={classNames('header-attendees', { 'active': showState && showState === 'list' })} onClick={this.openRoster}>
                        <img src={showState && showState === 'list' ? listOn : listOff} loading="lazy" className='chat-img' />
                        <h1>Hosts</h1>
                    </div>
                    <div className={classNames('header-attendees', { 'active': showState && showState === 'chat' })} onClick={this.openChat}>
                        <img src={showState && showState === 'chat' ? chatOn : chatOff} loading="lazy" className='chat-img' />
                        <h1>Chat</h1>
                    </div>
                </div> : null}
                <Fragment>
                    <div>
                        <ul>
                            <li>
                                {`Hosts and Guests (${Object.entries(hosts).length})`}
                            </li>
                            {hosts ?
                                Object.entries(hosts).map(([id, participant], i) => {
                                    return (participant &&
                                        <li key={i}>
                                            <div className="participant-details" >
                                                <img
                                                    src={participant.photoURL && validLink(participant.photoURL) ? participant.photoURL : participant.picture && validLink(participant.picture) ? participant.picture : userPlaceholder}
                                                    className="participant-avatar"
                                                    alt='avatar'
                                                />
                                                <span className="participant-username">
                                                    {participant.displayName || participant.name}
                                                </span>
                                                {role && fanoutClient.checkBannedUser(id) && !participant.isOwner && ((role === 'presenter') || (role === 'moderator')) ?
                                                    <img
                                                        src={moreIcon}
                                                        loading='lazy'
                                                        alt='...'
                                                        className='more'
                                                        onClick={() => this.setState({ moreId: ((!moreId) || (moreId && moreId !== id)) ? id : null })}
                                                    />
                                                    : null
                                                }
                                                {id && moreId && moreId === id ?
                                                    <ClickOutside
                                                        className='more-menu'
                                                        clickOutside={() => this.setState({ moreId: null })}
                                                    >
                                                        <button
                                                            className='btn btn-ban'
                                                            onClick={() => this.banUser(id)}
                                                        >
                                                            {`Ban ${participant.displayName || participant.name}`}
                                                        </button>
                                                    </ClickOutside>
                                                    : null}
                                            </div>
                                        </li>
                                    );
                                }) : null}
                            {/* {!(role && role === 'guest_speaker') && audience && Object.entries(audience).length ?
                                <li>{`Anonymous watchers (${Object.entries(audience).filter(([id, participant]) => participant.isAnonymous).length})`}</li>
                                : null
                            } */}
                            {!(role && role === 'guest_speaker') && audience && Object.entries(audience).length ?
                                <li>{`Audience (${Object.entries(audience).filter(([id, participant]) => !participant.isAnonymous).length})`}</li>
                                : null
                            }
                            {!(role && role === 'guest_speaker') && audience ?
                                Object.entries(audience).filter(([id, participant]) => !participant.isAnonymous).map(([id, participant], i) => {
                                    return (participant &&
                                        <li key={i}>
                                            <div className="participant-details" >
                                                <img
                                                    src={participant.photoURL && validLink(participant.photoURL) ? participant.photoURL : participant.picture && validLink(participant.picture) ? participant.picture : userPlaceholder}
                                                    className="participant-avatar"
                                                    alt='avatar'
                                                />
                                                <span className="participant-username">
                                                    {participant.displayName || participant.name}
                                                </span>
                                                {role && fanoutClient.checkBannedUser(id) && ((role === 'presenter') || (role === 'moderator')) ?
                                                    <img
                                                        src={moreIcon}
                                                        loading='lazy'
                                                        alt='...'
                                                        className='more'
                                                        onClick={() => this.setState({ moreId: ((!moreId) || (moreId && moreId !== id)) ? id : null })}
                                                    />
                                                    : null
                                                }
                                                {id && moreId && moreId === id ?
                                                    <ClickOutside
                                                        className='more-menu'
                                                        clickOutside={() => this.setState({ moreId: null })}
                                                    >
                                                        <button
                                                            className='btn btn-ban'
                                                            onClick={() => this.banUser(id)}
                                                        >
                                                            {`Ban ${participant.displayName || participant.name}`}
                                                        </button>
                                                    </ClickOutside>
                                                    : null}
                                            </div>
                                        </li>
                                    );
                                }) : null}
                            {role && ((role === 'presenter') || (role === 'moderator')) && bannedUsers && Object.entries(bannedUsers).length ?
                                <li>{`Banned (${Object.entries(bannedUsers).length})`}</li>
                                : null
                            }
                            {role && ((role === 'presenter') || (role === 'moderator')) && bannedUsers ?
                                Object.entries(bannedUsers).map(([id, participant], i) => {
                                    return (participant &&
                                        <li key={i}>
                                            <div className="participant-details" >
                                                <img
                                                    src={participant.photoURL && validLink(participant.photoURL) ? participant.photoURL : participant.picture && validLink(participant.picture) ? participant.picture : userPlaceholder}
                                                    className="participant-avatar"
                                                    alt='avatar'
                                                />
                                                <span className="participant-username">
                                                    {participant.displayName || participant.name}
                                                </span>
                                                <img
                                                    src={moreIcon}
                                                    loading='lazy'
                                                    alt='...'
                                                    className='more'
                                                    onClick={() => this.setState({ moreId: ((!moreId) || (moreId && moreId !== id)) ? id : null })}
                                                />
                                                {id && moreId && moreId === id ?
                                                    <ClickOutside
                                                        className='more-menu'
                                                        clickOutside={() => this.setState({ moreId: null })}
                                                    >
                                                        <button
                                                            className='btn btn-ban'
                                                            onClick={() => this.unBanUser(id)}
                                                        >
                                                            {`Lift the ban ${participant.displayName || participant.name}`}
                                                        </button>
                                                    </ClickOutside>
                                                    : null}
                                            </div>
                                        </li>
                                    );
                                }) : null}
                        </ul>
                    </div>
                </Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        hosts: state.hosts,
        audience: state.audience,
        bannedUsers: state.bannedUsers
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const RosterContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Roster);

export default RosterContainer; 