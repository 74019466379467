import React, { Component } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import fanoutClient from '../../../utils/FanoutClient';
import * as roomActions from "../../../reducers/room";

class StreamingCounter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds: 0
        };
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.handleStreamingGetReady = this.handleStreamingGetReady.bind(this);
        this.handleStreamingAtEase = this.handleStreamingAtEase.bind(this);
        this.sendAtEase = this.sendAtEase.bind(this);
        fanoutClient.on('streamingGetReady', this.handleStreamingGetReady);
        fanoutClient.on('streamingAtEase', this.handleStreamingAtEase);
    }

    componentWillUnmount() {
        fanoutClient.removeListener('streamingGetReady', this.handleStreamingGetReady);
        fanoutClient.removeListener('streamingAtEase', this.handleStreamingAtEase);
    }

    handleStreamingGetReady(data) {
        const { seconds } = this.state;
        const { streamingCounter } = this.props;

        if (streamingCounter && seconds)
            return;

        if (data && data.startAt) {
            let delta = data.delta || 0;
            let seconds = moment.utc(new Date(data.startAt)).diff(moment.utc(new Date()), 'seconds') || 0;

            this.setState({
                seconds: ((seconds && delta && seconds > delta && Math.abs(delta - seconds) > 1) || (delta && delta > seconds && Math.abs(delta - seconds) > 1)) ? delta : seconds
            }, () => {
                this.startTimer();
            });
        }
    }

    handleStreamingAtEase() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    startTimer() {
        if (this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({
            seconds: seconds
        });

        // Check if we're at zero.
        if (seconds === -2) {
            if (this.timer) {
                clearInterval(this.timer);
            }

            const { setStreamingCounter } = this.props;
            setStreamingCounter(false);
        }
    }

    sendAtEase() {
        fanoutClient.sendStreamingAtEase();
    }

    render() {
        const { seconds } = this.state;
        const { streaming, streamingCounter, t, role } = this.props;

        return (
            <div>
                {streamingCounter && role && role !== "audience" && !streaming ?
                    <div className='streaming-counter'>
                        <p className='counter-text'>{t('start_streaming_text')}</p>
                        <p className='counter'>{((!seconds) || (seconds && seconds < 0)) ? 0 : seconds}</p>
                        {role && ((role === 'presenter') || (role === 'moderator')) ?
                            <button
                                className='btn btn-at-ease'
                                onClick={this.sendAtEase}
                            >
                                {t('cancel')}
                            </button>
                            : null
                        }
                    </div>
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        streaming: state.room.streaming,
        streamingCounter: state.room.streamingCounter
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setStreamingCounter: (value) => {
            dispatch(roomActions.setStreamingCounter({ streamingCounter: value }));
        }
    };
};

const StreamingCounterContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StreamingCounter);

export default withTranslation()(StreamingCounterContainer);