import React, { Component } from "react";
import PropTypes from "prop-types";
import SettingsOn from "../../../../resources/icons/room/settings-active.svg";
import SettingsOff from "../../../../resources/icons/room/settings.svg";
import { isMobile } from "../../../../utils/HelperFunctions";

class SettingsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: isMobile(),
      hover: false
    };
  }

  render() {
    const {
      attendeesSettingsOpened,
      toggle,
      isBottomBar
    } = this.props;
    const { hover, isMobile } = this.state;
    return (
      <li
        id="settings-container"
        className={attendeesSettingsOpened ? "active" : ""}
        onMouseEnter={() => {
          !isMobile && this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          !isMobile && this.setState({ hover: false });
        }}
      >
        <a
          data-tip
          data-for="toggle-settings"
          className={" " + (attendeesSettingsOpened ? "on" : "off")}
          onClick={() => toggle()}
          title='Settings'
        >
          <img
            src={attendeesSettingsOpened || hover ? SettingsOn : SettingsOff}
          />
          {isBottomBar && (
            <div>
              <span>Settings</span>
            </div>
          )}
        </a>
      </li>
    );
  }
}

SettingsButton.propTypes = {
  attendeesSettingsOpened: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  isBottomBar: PropTypes.bool.isRequired
};

export default SettingsButton;
