import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    eventItem: null,
    userInfo: null,
    showKnocks: false,
    showAddGuest: false,
    showCopyGuest: false,
    roomMessage: {
        displayRoomMessage: false,
        timer: 3000,
        message: null,
        type: null
    },
    streaming: false,
    streamed: false,
    recording: null,
    audienceView: false,
    approvedEntry: null,
    modalEventOverrun: false,
    goingOut: false,
    showBroadcast: false,
    streamingCounter: false,
    filePresentation: {},
    selfView: true,
    lowBandwidthMode: false,
    exitModal: false,
    continueModal: false
}

export const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        showKnocks: (state, action) => {
            return Object.assign({}, state, action.payload);
        },
        clear: (state, action) => {
            const clearData = {
                userInfo: null,
                eventItem: null,
                showKnocks: false,
                streaming: null,
                inboundVideo: true,
                modalEventOverrun: false,
                goingOut: false
            }
            return Object.assign({}, state, clearData);
        },
        displayMessage: (state, action) => {
            let messageState = {
                displayRoomMessage: true,
                message: action.payload.message,
                timer: action.payload.timer,
                type: action.payload.type
            }
            return Object.assign({}, state, { roomMessage: messageState });
        },
        hideMessage: (state, action) => {
            let messageState = {
                displayRoomMessage: false,
                timer: 3000,
                message: state.roomMessage.message,
                type: state.roomMessage.type
            }
            return Object.assign({}, state, { roomMessage: messageState });
        },
        setStreaming: (state, action) => {
            try {
                delete action.payload["id"];
            } catch (error) {
                console.error('Error with delete id');
            }
            return Object.assign({}, state, action.payload);
        },
        setModalEventOverrun: (state, action) => {
            return Object.assign({}, state, { modalEventOverrun: action.payload.modalEventOverrun });
        },
        setGoingOut: (state, action) => {
            return Object.assign({}, state, { goingOut: action.payload.goingOut });
        },
        setUserInfo: (state, action) => {
            return Object.assign({}, state, { userInfo: action.payload.userInfo });
        },
        setEventItem: (state, action) => {
            return Object.assign({}, state, { eventItem: action.payload.eventItem });
        },
        setStreamingCounter: (state, action) => {
            return Object.assign({}, state, { streamingCounter: action.payload.streamingCounter });
        },
        showExitModal: (state, action) => {
            return Object.assign({}, state, { exitModal: action.payload.exitModal, continueModal: action.payload.continueModal });
        },
        setLowBandwidthMode: (state, action) => {
            return Object.assign({}, state, { lowBandwidthMode: action.payload });
        },
        showSelfView: (state, action) => {
            return Object.assign({}, state, { selfView: action.payload });
        },
        setAudienceView: (state, action) => {
            return Object.assign({}, state, { audienceView: action.payload.audienceView });
        },
        setApprovedEntry: (state, action) => {
            return Object.assign({}, state, { approvedEntry: action.payload.approvedEntry });
        },
    },
});

const { actions, reducer } = roomSlice;

// export individual action creator functions
export const {
    showKnocks,
    clear,
    displayMessage,
    hideMessage,
    setStreaming,
    setModalEventOverrun,
    setGoingOut,
    setEventItem,
    setUserInfo,
    setStreamingCounter,
    showExitModal,
    setLowBandwidthMode,
    showSelfView,
    setAudienceView,
    setApprovedEntry
} = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;