import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CameraOn from "../../../../resources/icons/room/camera-active.svg";
import CameraOff from "../../../../resources/icons/room/camera.svg";
import { isMobile } from "../../../../utils/HelperFunctions";

class VideoButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: isMobile(),
      hover: false,
      disableBtn: false
    };
  }

  disableButton() {
    this.setState({
      disableBtn: true
    }, () => {
      this.disableBtnTimeout = setTimeout(() => {
        this.setState({
          disableBtn: false
        });
      }, 2000);
    })
  }

  componentWillUnmount() {
    if (this.disableBtnTimeout) {
      clearTimeout(this.disableBtnTimeout);
    }
  }

  render() {
    const { videoEnabled, toggle, isBottomBar } = this.props;
    const { hover, isMobile, disableBtn } = this.state;

    return (
      <li
        className={classNames({ "active": videoEnabled }, { 'disable': disableBtn })}
        onMouseEnter={() => {
          !isMobile && this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          !isMobile && this.setState({ hover: false });
        }}
      >
        <a
          data-tip
          data-for="toggle-video"
          title={videoEnabled ? "Turn off" : "Turn on"}
          onClick={() => {
            toggle();
            this.disableButton();
          }}
        >
          <img src={videoEnabled ? CameraOn : CameraOff} />
          {isBottomBar && (
            <div>
              <span>{videoEnabled ? "Camera on" : "Camera off"}</span>
            </div>
          )}
        </a>
      </li>
    );
  }
}

VideoButton.propTypes = {
  videoEnabled: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  isBottomBar: PropTypes.bool.isRequired
};

export default VideoButton;