import React, { Component } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ChatroomOn from "../../../resources/assets/Chatroom-Active.svg";
import ChatroomOff from "../../../resources/assets/Chatroom.svg";
import { isMobile, numberFormatter } from "../../../utils/HelperFunctions";

class AttendeesListButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            hover: false,
        };
    }

    render() {
        const {
            isOpen,
            toggle,
            isBottomBar,
            t,
            isAdmin,
            newMessage,
            hosts,
            audience,
            bannedUsers,
            role
        } = this.props;
        const { hover, isMobile } = this.state;

        let isGuestSpeaker = (role && role === 'guest_speaker');
        let nbParticipants = Object.entries(hosts) && Object.entries(hosts).length ? Object.entries(hosts).length : 0;
        if (!isGuestSpeaker && isAdmin) {
            nbParticipants += Object.entries(audience) && Object.entries(audience).length ? Object.entries(audience).length : 0;
        }
        // nbParticipants += Object.entries(bannedUsers) && Object.entries(bannedUsers).length ? Object.entries(bannedUsers).length : 0;

        return (
            <li
                className={isOpen ? "active" : ""}
                onMouseEnter={() => {
                    !isMobile && this.setState({ hover: true });
                }}
                onMouseLeave={() => {
                    !isMobile && this.setState({ hover: false });
                }}
            >
                <a
                    data-tip
                    data-for="toggle-attendees"
                    className={" " + (isOpen ? "on" : "off")}
                    title={isOpen ? t('close_chat_hosts') : t('open_chat_hosts')}
                    onClick={() => toggle()}
                >
                    <span
                        className={"attendees-number activeUsers" + (isOpen || hover ? " active" : "")}
                    >
                        {numberFormatter(nbParticipants)}
                    </span>
                    <img src={isOpen || hover ? ChatroomOn : ChatroomOff} />
                    {isBottomBar && (
                        <div>
                            <span>{t('chat_hosts')}</span>
                        </div>
                    )}
                    {newMessage && <span className='chat-badge' />}
                </a>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        newMessage: state.chat.newMessage,
        hosts: state.hosts,
        audience: state.audience,
        bannedUsers: state.bannedUsers
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const AttendeesListButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AttendeesListButton);

export default withTranslation()(AttendeesListButtonContainer);