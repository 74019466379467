import { Defines } from './FanoutDefines';
import firebaseClient from './FirebaseClient';
import Cookies from "js-cookie";
import moment from 'moment';
import * as chatActions from "../reducers/chat";
import * as audienceActions from "../reducers/audience";
import * as hostsActions from "../reducers/hosts";
import * as bannedActions from "../reducers/bannedUsers";
import * as knockActions from "../reducers/knocks";
import * as appActions from "../reducers/app";
import * as roomActions from "../reducers/room";
import * as knocksActions from "../reducers/knocks";
import { v1 as uuidv1 } from 'uuid';
import { getParticipantsTerms, getReconnectToken, setReconnectToken } from '../utils/HelperFunctions';
const EventEmitter = require('events');
const { fanoutId, messageDispatcherPort, messageDispatcherHost } = require('../config').default;

class FanoutClient extends EventEmitter {
    /**
     * Constructor
     * @param store Redux store
     */
    constructor(store) {
        super();

        this._store = store;
        this._ws = null;
        this._keepAliveinterval = null;
        this._shouldReconnectToWS = true;
        this.connectToWS = this.connectToWS.bind(this);
        this.cleanTheRoom = this.cleanTheRoom.bind(this);
        this.join = this.join.bind(this);
        this.reconnectAsMe = this.reconnectAsMe.bind(this);

        this.handleSignalClientMessage = this.handleSignalClientMessage.bind(this);
        this.handleFanoutStateChanged = this.handleFanoutStateChanged.bind(this);
        this.responses = {};
    }

    /**
     * Initializa new connection to fanout
     * @param props
     */
    async connectToFanout(data) {
        const { linkId, username } = data;
        // Connect to ws
        try {
            await this.connectToWS(linkId, username);
        } catch (e) {
            console.error('Could not connect to WS', e.message)
        }
        // Subscribe to fanout events on firebase
        console.log('------------------------');
        firebaseClient.subscribeToFanoutStateChanges(fanoutId, this.handleFanoutStateChanged);
    }

    connectToWS(linkId, username, shouldReconnectToRoom = false) {
        console.log('Connecting to message dispatcher', `${messageDispatcherHost}:${messageDispatcherPort}`)
        Cookies.set('linkId', linkId, { sameSite: 'None', secure: true, domain: messageDispatcherHost });
        document.cookie = "linkId=" + linkId + ";SameSite=None; Secure"
        let connected = false;

        return new Promise((resolve, reject) => {
            try {
                // Create WebSocket connection.
                const socket = new WebSocket(`${messageDispatcherHost}:${messageDispatcherPort}`);
                this._ws = socket;
                setTimeout(() => {
                    reject(false);
                }, 60000);

                // Connection opened
                socket.addEventListener('open', (event) => {
                    connected = true;
                    // resolve(true);
                    let rid = uuidv1();
                    console.log('Connected to message dispatcher');
                    socket.send(JSON.stringify({
                        message: 'Hello Server!',
                        type: Defines.Fanout.Signalling.VerifyLink,
                        request: Defines.Fanout.Signalling.VerifyLink,
                        linkId: linkId,
                        website: process.env.website,
                        rid
                    }));
                    if (this._keepAliveinterval) {
                        clearInterval(this._keepAliveinterval);
                        this._keepAliveinterval = null;
                    }
                    if (shouldReconnectToRoom) {
                        this.emit('reconnect');
                    }

                    // this._keepAliveinterval = setInterval(() => {
                    //     // socket.ping()
                    // }, 5000);
                });

                // Listen for messages
                socket.addEventListener('message', (event) => {
                    console.log('Message from server: ', event.data);
                    let parsed = null;
                    try {
                        parsed = JSON.parse(event.data);
                    } catch (e) {
                        console.error('Could not parse fanout message', event.data)
                    }
                    if (parsed) {
                        if (parsed.request === Defines.Fanout.Signalling.Auth || parsed.request === Defines.Fanout.Signalling.VerifyLink)
                            resolve(true);
                        this.handleSignalClientMessage(parsed);
                    }
                });
                // Listen for possible errors
                socket.addEventListener('error', (event) => {
                    console.error('WebSocket error: ', event);
                    if (!connected) {
                        reject(event);
                    }
                });
                // Listen for close event
                socket.addEventListener('close', (event) => {
                    console.log(`Got websocket close event for ${username} on likId ${linkId}.`);
                    if (this._keepAliveinterval) {
                        clearInterval(this._keepAliveinterval);
                        this._keepAliveinterval = null;
                    }
                    if (this._shouldReconnectToWS) {
                        setTimeout(() => {
                            this.connectToWS(linkId, username, true);
                        }, 5000);
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    async join(linkId, name, emailVerificationCode, title, company, avatar = null, email, skipAuth = false, role = null, eventData = null) {
        console.log('About to join to conference [%s] as [%s]', linkId, name);
        let rid = uuidv1();
        // Get user token
        let idToken = null;

        if (!skipAuth) {
            idToken = await firebaseClient.getUserToken();
        }

        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'join',
                    message: 'tfae'
                });
                console.log('Timeout for authentication expired');
                reject('Timeout for authentication expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('Successfully joined', data);
                    resolve(data);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        let authData = {
            linkId,
            idToken,
            emailVerificationCode,
            name,
            title,
            company,
            avatar,
            email,
            role,
            ...eventData
        }

        let terms = false;

        if (window && window.location && window.location.pathname && (typeof window.location.pathname === 'string' || window.location.pathname instanceof String)) {
            let splitPathname = window.location.pathname.split("/");

            if (splitPathname && splitPathname.length) {
                terms = getParticipantsTerms(splitPathname[splitPathname.length - 1]);
            }
        }

        if (terms) {
            authData.participant_tos_accepted = true;
        }

        this._ws.send(JSON.stringify({
            message: 'Joining to conference!',
            request: Defines.Fanout.Signalling.Auth,
            rid,
            payload: authData
        }));

        return p;
    }

    async register(linkId, name, emailVerificationCode, title, company, email) {
        console.log('About to register to conference [%s] as [%s]', linkId, name);
        let rid = uuidv1();
        // Get user token
        let idToken = await firebaseClient.getUserToken();

        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'register',
                    message: 'tfae'
                });
                reject('Timeout for authentication expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('Successfully joined', data);
                    resolve(data);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        let registerData = {
            linkId,
            idToken,
            emailVerificationCode,
            name,
            title,
            company,
            email
        }

        let terms = false;

        if (window && window.location && window.location.pathname && (typeof window.location.pathname === 'string' || window.location.pathname instanceof String)) {
            let splitPathname = window.location.pathname.split("/");

            if (splitPathname && splitPathname.length) {
                terms = getParticipantsTerms(splitPathname[splitPathname.length - 1]);
            }
        }

        if (terms) {
            registerData.participant_tos_accepted = true;
        }

        this._ws.send(JSON.stringify({
            message: 'Registering to conference!',
            request: Defines.Fanout.Signalling.Register,
            rid,
            payload: registerData
        }));

        return p;
    }

    async reconnectAsMe(email, eventId) {
        let reconnectToken = getReconnectToken({ email, eventId });

        if (!reconnectToken)
            return Promise.resolve(false);
        let rid = uuidv1();
        console.log('About to reconnect to conference', reconnectToken, email);

        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'reconnectAsMe',
                    message: 'tfre'
                });
                console.log('Timeout for reconnection expired');
                resolve(false);
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('Successfully reconnected', data);
                    resolve(data);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    resolve(false);
                }
            }
        });

        this._ws.send(JSON.stringify({
            message: 'Reconnecting to conference!',
            type: 'reconnect-me',
            request: 'reconnect-me',
            rid,
            payload: { reconnectToken, email }
        }));

        return p;
    }

    async joinConference(params) {
        if (!this._ws) {
            console.error('joinConference()  Could not connect to ws');
            throw new Error('Could not connect to ws');
        }
        console.log('joinConference()  About to join the conference [%s]', params.eventId);
        let rid = uuidv1();


        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'joinConference',
                    message: 'The most likely cause of this is a network issue. Please check your network connection and bandwidth, then click Reload to try again..',
                    title: 'Unable to connect to the event'
                });
                this.logToServer('warn', 'fanoutReqestExpired / joinConference / Timeout for join conference expired');
                reject('Timeout for join conference expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('joinConference()  Got event data', data);
                    resolve(data);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('joinConference()  Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        this._ws.send(JSON.stringify({
            type: Defines.Fanout.Signalling.JoinConference,
            request: Defines.Fanout.Signalling.JoinConference,
            payload: params,
            rid
        }));

        return p;
    }

    async joinRoom(params) {
        if (!this._ws) {
            console.error('joinRoom()  Could not connect to ws');
            throw new Error('Could not connect to ws');
        }
        console.log('About to join the conference [%s]', params.eventId);
        let rid = uuidv1();


        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'joinRoom',
                    message: 'The most likely cause of this is a network issue. Please check your network connection and bandwidth, then click Reload to try again..',
                    title: 'Unable to connect to the event'
                });
                this.logToServer('warn', 'fanoutReqestExpired / joinRoom / Timeout for join room expired');
                reject('Timeout for join room expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('joinRoom()  Got event data', data);
                    resolve(data);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('joinRoom()  Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        this._ws.send(JSON.stringify({
            type: Defines.Fanout.Signalling.JoinRoom,
            request: Defines.Fanout.Signalling.JoinRoom,
            payload: params,
            rid
        }));

        return p;
    }

    async peekRoom(params) {
        if (!this._ws) {
            console.error('peekRoom()  Could not connect to ws');
            throw new Error('Could not connect to ws');
        }
        console.log('About to join the conference [%s]', params.eventId);
        let rid = uuidv1();


        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'peekRoom',
                    message: 'The most likely cause of this is a network issue. Please check your network connection and bandwidth, then click Reload to try again..',
                    title: 'Unable to connect to the event'
                });
                this.logToServer('warn', 'fanoutReqestExpired / peekRoom / Timeout for join room expired');
                reject('Timeout for join room expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('peekRoom()  Got event data', data);
                    resolve(data);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('peekRoom()  Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        this._ws.send(JSON.stringify({
            type: Defines.Fanout.Signalling.PeekRoom,
            request: Defines.Fanout.Signalling.PeekRoom,
            payload: params,
            rid
        }));

        return p;
    }

    /**
     * Log message to server
     * @param level {string} info/warn/error/debug
     * @param message {string} Message to be logged
     * @returns {Promise<*>}
     */
    async logToServer(level, message) {
        let rid = uuidv1();
        console.log('About to log %s message %s', level, message);

        let payload = {
            level,
            message
        }

        if (this._ws) {
            this._ws.send(JSON.stringify({
                request: Defines.Fanout.Signalling.Log,
                type: Defines.Fanout.Signalling.Log,
                rid,
                payload
            }));
        }

        return;
    }

    async sendRequest(data, onResponse) {
        if (!this._ws) {
            console.error('Could not connect to ws');
            throw new Error('Could not connect to ws');
        }
        let rid = uuidv1();
        let { request } = data || { request: `unknown(${rid})` };
        console.log('About to send request [%s]', request);


        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit(`ReqestExpired`, {
                    where: `${request}`,
                    message: 'Timeout for request expired'
                });
                this.logToServer('warn', `ReqestExpired / ${request}(${rid}) / Timeout for request ${request}(${rid}) expired`);
                reject('Timeout for request expired');
            }, 20000);

            this.responses[rid] = async (data) => {
                try {
                    clearTimeout(t);
                    console.log('Got event data', data);
                    if (request && request === Defines.Fanout.Signalling.Media.ConsumeResume && data.eventId && data.uid) {
                        const { lowBandwidthMode } = this._store.getState().room;

                        if (lowBandwidthMode) {
                            data.lowBandwidthMode = lowBandwidthMode;
                        }
                    }
                    if (onResponse) {
                        await onResponse(data);
                        resolve(data);
                    } else {
                        resolve(data);
                    }
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        this._ws.send(JSON.stringify({ ...data, rid }));

        return p;
    }

    /**
     * Send chat message
     * @param props
     */
    sendChatMessage(payload, callState) {
        let parsePayload = JSON.parse(payload);
        parsePayload.rid = uuidv1();
        parsePayload = JSON.stringify(parsePayload);

        this.updateSignallingServer('chat-message', parsePayload, callState);
    }

    /**
     * Connect to chat
     * @param props
     */
    sendChatConnect(callState) {
        this.updateSignallingServer('chat-connect', {}, callState);
    }

    /**
     * Connect to chat
     * @param props
     */
    sendChatDisconnect(callState) {
        return this.updateSignallingServer('chat-disconnect', {}, callState);
    }

    /**
     * Connect to chat
     * @param props
     */
    sendStartStreaming(callState) {
        this.updateSignallingServer('streaming', { streaming: true }, callState);
    }

    /**
     * Connect to chat
     * @param props
     */
    sendStopStreaming(callState) {
        this.updateSignallingServer('streaming', { streaming: false }, callState);
    }

    /**
     * Send end for audience
     * @param props
     */
    endEventForAudience(callState) {
        this.updateSignallingServer('end-audience', {}, callState);
    }

    /**
     * Knck to connect as speaker
     * @param props
     */
    sendKnockRequest(callState, payload = {}) {
        let rid = uuidv1();
        this.updateSignallingServer('knock-request', payload, callState, rid);
        let data = { ...callState, rid, payload }
        this._store.dispatch(knockActions.knocked(data));
    }

    /**
     * Grant knock request
     * @param request_id received in reques as rid roperty
     * @param callState {{conferenceAlias, uid}}
     */
    sendKnockGranted(request_id, callState) {
        this.updateSignallingServer('knock-granted', {}, callState, request_id);
        this._store.dispatch(knockActions.accept({ rid: request_id }));
    }

    /**
     * Reject knock request
     * @param request_id received in reques as rid roperty
     * @param callState {{conferenceAlias, uid}}
     */
    sendKnockRejected(request_id, callState) {
        this.updateSignallingServer('knock-rejected', {}, callState, request_id);
    }

    /**
     * Revoke knock request
     * @param request_id received in reques as rid roperty
     * @param callState {{conferenceAlias, uid}}
     */
    sendKnockRevoked(request_id, callState) {
        this.updateSignallingServer('knock-revoked', {}, callState, request_id);
        let data = { ...callState, rid: request_id }
        this._store.dispatch(knockActions.revoke(data));
    }

    /**
     * Verify email request
     * @param email user email
     */
    async verifyEmail(email, role) {
        let rid = uuidv1();
        let idToken = await firebaseClient.getUserToken();

        let verifyData = {
            email,
            role,
            idToken
        };

        let terms = false;

        if (window && window.location && window.location.pathname && (typeof window.location.pathname === 'string' || window.location.pathname instanceof String)) {
            let splitPathname = window.location.pathname.split("/");

            if (splitPathname && splitPathname.length) {
                terms = getParticipantsTerms(splitPathname[splitPathname.length - 1]);
            }
        }

        if (terms) {
            verifyData.participant_tos_accepted = true;
        }

        return this.updateSignallingServer('verify-email', verifyData, {}, rid);
    }

    /**
     * Ban user
     * @param userId
     * @param banned
     */
    banUser(userId, banned) {
        let rid = uuidv1();
        this.updateSignallingServer('ban-user', { userId, banned }, {}, rid);
    }

    /**
    * Ban user by message id
    * @param id
    */
    banUserByMessage(id) {
        let rid = uuidv1();
        this.updateSignallingServer('chat-ban-user-by-message', { id }, {}, rid);
    }

    /**
    * Delete message by id
    * @param id
    */
    deleteMessage(id, callState) {
        let rid = uuidv1();
        this.updateSignallingServer('chat-delete-message', { id }, callState, rid);
        this._store.dispatch(chatActions.deleteMessageById({ id }));
    }


    /**
     * Return accepted knock if any
     */
    isKnockGranted() {
        let knocks = this._store.getState().knocks;
        for (const [rid, knock] of Object.entries(knocks)) {
            if (knock.accepted) {
                console.log("Found accepted knock", rid, knock);
                return knock;
            }
        }
        return null;
    }

    /**
     * Send call ended to WSS
     * @param props
     */
    sendEndCall(callState, params) {
        let rid = uuidv1();
        let room = this._store.getState().room;

        if (room && (room.streaming || room.streamed) && params && params.role && (params.role === 'presenter' || params.role === 'moderator')) {
            this._store.dispatch(roomActions.setStreaming({ conference: 'ended' }));
        }
        return this.updateSignallingServer('end-call', {}, callState, rid);
    }

    /**
     * Send call stop to WSS
     * @param props
     */
    sendStopCall(callState) {
        return this.updateSignallingServer('stop', {}, callState);
    }

    /**
     * Verify email request
     * @param email user email
     */
    sendExtendCall(timestamp, alias) {
        let rid = uuidv1();
        this.updateSignallingServer('extend-call', { timestamp, alias }, {}, rid);
    }

    /**
     * Send GetReady signal to hosts
     * @param startAt timestamp
     */
    sendStreamingGetReady(startAt) {
        this.updateSignallingServer('get-ready', { startAt }, {});
    }

    /**
     * Send GetReady signal to hosts
     * @param startAt timestamp
     */
    sendStreamingAtEase() {
        this.updateSignallingServer('at-ease', {}, {});
    }

    /**
     * Send call ended to WSS
     * @param props
     */
    reconnectMeToEvent(callState) {
        const { email } = callState || { email: 'unknown' };
        let rid = uuidv1();
        let reconnectToken = window.localStorage.getItem(`reconnectToken:${(email || 'unknown').replace('@', '_')}`);
        this.updateSignallingServer('reconnect-me', { reconnectToken, email }, callState, rid);
    }

    /**
     * Destroy connection to fanout
     * @param props
     */
    disconnectFromFanout(props = {}) {
        console.log('About to disconnect from fanout');
        this._shouldReconnectToWS = false;
        if (this._keepAliveinterval) {
            clearInterval(this._keepAliveinterval);
            this._keepAliveinterval = null;
        }
        if (this._ws) {
            this._ws.close();
            this._ws = null;
        }
        const { uid, conferenceAlias } = props;
        // Subscribe from fanout events on firebase
        if (uid && conferenceAlias) {
            firebaseClient.unsubscribeFromSignallingClientChanges(uid, conferenceAlias);
        }
    }


    handleFanoutStateChanged(state) {
        try {
            console.log("handleFanoutStateChange", state);
            this.emit('fanoutStateChanged', state)

            // // check if this.sessionClosed or whatever the flag is if not then set reconnecting
            // if (state !== Defines.Fanout.Status.Running &&
            //     this.previousFanoutState === Defines.Fanout.Status.Running && !this.sessionEnded)
            //     console.warn("Reconnecting");
            //
            // if (this.previousFanoutState != state)
            //     this.previousFanoutState = state;
        }
        catch (err) {
            console.error(err);
        }
    }

    handleSignalClientMessage(data) {
        try {
            console.log("handleSignalClientMessage", data);
            let { rid, payload } = data;
            // This no good in long run cause I think anyone can just change this value in JS right?
            // if ((data.sessionId !== this.sessionId) && (data.request !== Defines.Fanout.Signalling.Closed || data.request !== Defines.Fanout.Signalling.ForceStop)) {
            //     console.error("This message was not meant for me, returning");
            //     return;
            // }
            switch (data.request) {
                case Defines.Fanout.Signalling.CreateRoom:
                    this.emit('createRoomResponse', data);
                    break;
                case Defines.Fanout.Signalling.JoinRoom:
                    this.emit('joinRoomResponse', data);
                    break;
                case Defines.Fanout.Signalling.PeekRoom:
                    this.emit('peekRoomResponse', data);
                    break;
                case Defines.Fanout.Signalling.JoinConference:
                    this.emit('joinConferenceResponse', data);
                    break;
                case Defines.Fanout.Signalling.Initial:
                    this.emit('joinRoomResponse', data);
                    break;
                case Defines.Fanout.Signalling.Join:
                    this.emit('streamingJoin', data);
                    if (data && data.status && data.status === 200 && data.payload && data.payload.eventId) {
                        this._store.dispatch(roomActions.setApprovedEntry({ approvedEntry: data.payload.eventId }));
                    }
                    break;
                // case Defines.Fanout.Signalling.Transport.Create:
                //     this.emit('createTransportResponse', data);
                //     break;
                // case Defines.Fanout.Signalling.Transport.Connect:
                //     this.emit('connectTransportResponse', data);
                //     break;
                case Defines.Fanout.Signalling.Media.Consume:
                    const { audienceView } = this._store.getState().room;

                    if (data && data.payload && data.payload.sessionId && audienceView) {
                        data.payload.audienceView = true;
                    }
                    this.emit('consumeResponse', data);
                    break;
                case Defines.Fanout.Signalling.DataChannel.Consume:
                    console.warn('Don\'t want to see this: Defines.Fanout.Signalling.DataChannel.Consume');
                    this.emit('consumeDataResponse', data);
                    break;
                case Defines.Fanout.Signalling.DataChannel.Produce:
                    console.warn('Don\'t want to see this: Defines.Fanout.Signalling.DataChannel.Produce');
                    this.emit('produceDataResponse', data);
                    break;
                case Defines.Fanout.Signalling.Media.NewProducer:
                    this.emit('newProducerResponse', data);
                    break;
                case Defines.Fanout.Signalling.Media.ProducerDeleted:
                    this.emit('deletedProducer', data.payload);
                    break;
                case Defines.Fanout.Signalling.Media.ProducePause:
                    this.emit('pauseProducer', data.payload);
                    break;
                case Defines.Fanout.Signalling.Media.ProduceResume:
                    this.emit('resumeProducer', data.payload);
                    break;
                case Defines.Fanout.Signalling.DataChannel.NewProducer:
                    console.warn('Don\'t want to see this: Defines.Fanout.Signalling.DataChannel.NewProducer');
                    this.emit('newDataProducerResponse', data);
                    break;
                case Defines.Fanout.Signalling.Closed:
                    this.emit('peerClosedResponse', data);
                    break;
                case Defines.Fanout.Signalling.Reconnect:
                    this.emit('rejoin');
                    break;
                case Defines.Fanout.Signalling.Stop:
                    this.emit('roomStopped', data);
                    this._store.dispatch(roomActions.setStreaming({ streaming: false }));
                    break;
                case Defines.Fanout.Signalling.End:
                    this.emit('roomClosed', data);
                    this._store.dispatch(roomActions.setStreaming({ streaming: false }));
                    break;
                case Defines.Fanout.Signalling.Auth:
                    if ((data.status !== Defines.Response.OK) && (data.status !== Defines.Response.NotAcceptable)) {
                        // Disconnect and don't reconnect
                        this.disconnectFromFanout();
                    }
                    if (data && data.payload && data.payload.reconnectToken) {
                        console.log('About to set reconnectToken to', data.payload.reconnectToken);
                        let userInfo = JSON.parse(window.localStorage.getItem('userInfo') || '{}');
                        this._store.dispatch(roomActions.setUserInfo({ userInfo }));
                        let email = (userInfo.email || 'unknown');
                        const { eventItem } = this._store.getState().room;
                        let storageData = {
                            email,
                            reconnectToken: data.payload.reconnectToken,
                            expiresAt: data.payload.reconnect_token_expires_at,
                            eventId: data.payload.eventId,
                            startDate: eventItem && eventItem.startDate ? eventItem.startDate : new Date().getTime()
                        }
                        setReconnectToken(storageData);
                    }
                    this.emit('authResponse', data);
                    break;
                case Defines.Fanout.Signalling.Register:
                    if (data && data.payload && data.payload.reconnectToken) {
                        console.log('About to set reconnectToken to', data.payload.reconnectToken);
                        let userInfo = JSON.parse(window.localStorage.getItem('userInfo') || '{}');
                        this._store.dispatch(roomActions.setUserInfo({ userInfo }));
                        let email = (userInfo.email || 'unknown');
                        const { eventItem } = this._store.getState().room;
                        let storageData = {
                            email,
                            reconnectToken: data.payload.reconnectToken,
                            expiresAt: data.payload.reconnect_token_expires_at,
                            eventId: data.payload.eventId,
                            startDate: eventItem && eventItem.startDate ? eventItem.startDate : new Date().getTime()
                        }
                        setReconnectToken(storageData);
                    }
                    this.emit('registerResponse', data);
                    break;
                case Defines.Fanout.Signalling.ReconnectMe:
                    // if (data.status !== Defines.Response.OK) {
                    //     // Disconnect and don't reconnect
                    //     this.disconnectFromFanout();
                    // }
                    if (data && data.payload && data.payload.reconnectToken) {
                        let userInfo = JSON.parse(window.localStorage.getItem('userInfo') || '{}');
                        this._store.dispatch(roomActions.setUserInfo({ userInfo }));
                        let email = (userInfo.email || 'unknown');
                        const { eventItem } = this._store.getState().room;
                        let storageData = {
                            email,
                            reconnectToken: data.payload.reconnectToken,
                            expiresAt: data.payload.reconnect_token_expires_at,
                            eventId: data.payload.eventId,
                            startDate: eventItem && eventItem.startDate ? eventItem.startDate : new Date().getTime()
                        }
                        setReconnectToken(storageData);
                    }
                    this.emit('reconnectResponse', data);
                    break;
                case Defines.Fanout.Signalling.VerifyLink:
                    if (data.status !== Defines.Response.OK) {
                        // Disconnect and don't reconnect
                        this.disconnectFromFanout();
                    }
                    if (payload && payload.eventItem)
                        this._store.dispatch(roomActions.setEventItem({ eventItem: payload.eventItem }));
                    this.emit('verifyLinkResponse', data);
                    break;
                case Defines.Fanout.Signalling.EndForAudience:
                    if (data && data.payload) {
                        this._store.dispatch(roomActions.setStreaming(data.payload));
                    }
                    break;
                case Defines.Fanout.Signalling.Streaming:
                    this.emit('streamingResponse', data);
                    // TODO: Checked if stopped and end call if it is
                    if (data.payload && data.payload.conference && data.payload.conference === Defines.Fanout.Signalling.Ended) {
                        console.log('About to emit roomClosed');
                        this.emit('roomClosed', data);
                        this._store.dispatch(roomActions.setStreaming(data.payload));
                    } else {
                        if (data && data.result && data.result === 'error' && data.status) {
                            switch (data.status) {
                                case Defines.Response.Forbidden:
                                    this._store.dispatch(roomActions.displayMessage({ message: 'no_streaming_moderator', timer: 4000, type: 'error' }));
                                    break;
                                case Defines.Response.TooManyRequests:
                                    this._store.dispatch(roomActions.displayMessage({ message: 'too_many_started_events', timer: 4000, type: 'error' }));
                                    break;
                                default:
                                    console.warn('Use default case');
                                    this._store.dispatch(roomActions.displayMessage({ message: 'streaming_error', timer: 4000, type: 'error' }));
                                    break;
                            }
                        } else {
                            this._store.dispatch(roomActions.setStreaming(data.payload));
                        }
                    }
                    break;
                case Defines.Fanout.Signalling.Media.ConsumeResume:
                    this.emit('consumeResume', data.payload);
                    break;
                case Defines.Fanout.Signalling.VerifyEmail:
                    this.emit('verifyEmail', data); // true or false
                    console.log('Received email verification request status:', data);
                    // alert(data.error);
                    break;
                case Defines.Fanout.Signalling.BanUser:
                    this.emit('userBanned', data.result); // true or false
                    console.log('Received user banned request status:', data);
                    if (data && data.result && data.result === 'success' && data.status && data.status === 200 && data.payload) {
                        this._store.dispatch(bannedActions.updateListItem(data.payload));
                    }
                    // alert(data.error);
                    break;
                case Defines.Fanout.Signalling.Extend:
                    console.log('Signalling.Extend Data:', data);
                    // TODO: Set this info somewhere
                    this.emit('call-extended', data.payload);
                    if (this.overrunsTimeout) {
                        clearTimeout(this.overrunsTimeout);
                    }
                    if (this.closeModalTimeout) {
                        clearTimeout(this.closeModalTimeout);
                    }
                    this._store.dispatch(roomActions.setModalEventOverrun({ modalEventOverrun: false }));
                    this.sendExtendAgain(data.payload.timestamp);
                    break;
                case Defines.Fanout.Signalling.Error:
                    this.emit('signallingError', data);
                    console.error('received server error:', data.error);
                    // alert(data.error);
                    break;
                case Defines.Fanout.Chat.DeleteMessage:
                    this.emit('deleteMessage', data);
                    console.log('received delete chat message:', data);
                    const { id, ids } = data && data.payload ? data.payload : { id: null, ids: null };
                    if (id) {
                        this._store.dispatch(chatActions.deleteMessageById({ id }));
                    }
                    if (ids && ids.length) {
                        this._store.dispatch(chatActions.deleteMessagesByIds({ ids }));
                    }
                    break;
                case Defines.Fanout.Chat.Message:
                    this.emit('chatMessage', data);
                    console.log('received chat message:', data);
                    // alert(data.error);
                    break;
                case Defines.Fanout.Chat.Audience:
                    this.emit('chatAudienceUpdate', data);
                    console.log('received chat audience update:', data);
                    this._store.dispatch(audienceActions.updateList(data.payload));
                    break;
                case Defines.Fanout.Chat.Hosts:
                    this.emit('chatHostsUpdate', data);
                    console.log('received chat hosts update:', data);
                    this._store.dispatch(hostsActions.updateList(data.payload));
                    // alert(data.error);
                    break;
                case Defines.Fanout.Chat.BannedUsers:
                    this.emit('bannedUsers', data.payload);
                    console.log('received banned users:', data);
                    this._store.dispatch(bannedActions.updateList(data.payload));
                    // alert(data.error);
                    break;
                case Defines.Fanout.Chat.History:
                    this.emit('chatHistory', data.payload);
                    console.log('received chat history:', data);
                    const { history } = data && data.payload ? data.payload : { history: [] };
                    this._store.dispatch(chatActions.addHistory(history));
                    // alert(data.error);
                    break;
                case Defines.Fanout.Knock.Request:
                    this.emit('knockRequest', data);
                    this._store.dispatch(knockActions.knock(data.payload));
                    console.log('received knock request:', data);
                    break;
                case Defines.Fanout.Knock.Requests:
                    this._store.dispatch(knocksActions.setKnocksList(data.payload.requests));
                    console.log('received knock requests:', data);
                    break;
                case Defines.Fanout.Knock.Granted: {
                    console.log('received knock granted:', data);
                    let knocks = this._store.getState().knocks;
                    if (knocks[rid])
                        this.emit('knockGranted', data);
                    else
                        console.warn('Could not find request', rid);
                    this._store.dispatch(knockActions.accept(data.payload));
                    break;
                }
                case Defines.Fanout.Knock.Rejected: {
                    console.log('received knock rejected:', data);
                    let knocks = this._store.getState().knocks;
                    if (knocks[rid])
                        this.emit('knockRejected', data);
                    else
                        console.warn('Could not find request', rid);
                    this._store.dispatch(knockActions.reject(data.payload));
                    break;
                }
                case Defines.Fanout.Knock.Revoked:
                    this.emit('knockRevoked', data);
                    this._store.dispatch(knockActions.revoke(data.payload));
                    console.log('received knock revoked:', data);
                    break;
                case Defines.Fanout.DataChannel.SpeakerLayout:
                    console.log('SpeakerLayout Data:', data);
                    this.emit('speakerLayout', data.payload);
                    break;
                case Defines.Fanout.DataChannel.VideoPresentation:
                    console.log('VideoPresentation Data:', data);
                    this.emit('videoPresentation', data.payload);
                    break;
                case Defines.Fanout.Signalling.GetReady:
                    this.emit('streamingGetReady', data.payload);
                    this._store.dispatch(roomActions.setStreamingCounter({ streamingCounter: true }));
                    break;
                case Defines.Fanout.Signalling.AtEase:
                    this.emit('streamingAtEase', data.payload);
                    this._store.dispatch(roomActions.setStreamingCounter({ streamingCounter: false }));
                    break;
                case Defines.Fanout.Signalling.ActiveSpeaker:
                    this.emit('activeSpeaker', data);
                    break;
                case Defines.Fanout.Signalling.ChangeLayout:
                    if (!this.responses[rid]) {
                        this.emit('changeLayout', data);
                    }
                    break;
                case Defines.VideoPresentation.Started:
                case Defines.VideoPresentation.Stopped:
                case Defines.VideoPresentation.Played:
                case Defines.VideoPresentation.Paused:
                case Defines.VideoPresentation.Sought:
                    if (!this.responses[rid]) {
                        // If there is no callback for this request emit event
                        this.emit('videoPresentation', data);
                    }
                    break;
                default:
                    if (!this.responses[rid])
                        console.warn('Unknown message', data);
            }
            if (this.responses[rid]) {
                console.log('Found response for request', rid)
                this.responses[rid](payload);
            }
        } catch (error) {
            console.error('failed to handle socket message', error);
        }
    }

    checkBannedUser(userId) {
        let hosts = this._store.getState().hosts;

        let retValue = true;

        if (userId && hosts && Object.entries(hosts).length &&
            hosts[userId] && hosts[userId].role &&
            ((hosts[userId].role === 'presenter') || (hosts[userId].role === 'moderator') || (hosts[userId].role === 'banned'))
        ) {
            retValue = false;
        }

        return retValue;
    }

    /**
     * Create transport
     * @param type
     * @param call_state
     */
    createTransport(payload, call_state) {
        const { conferenceAlias, routerId, sessionId, username } = call_state;

        console.log('createTransport()', payload.type);
        if (payload.type === "recv" || payload.type === "send") {
            let createTransport = {
                request: Defines.Fanout.Signalling.Transport.Create,
                fanoutId,
                name: username,
                routerId,
                sessionId,
                alias: conferenceAlias,
                payload
            };
            if (this._ws) {
                this._ws.send(JSON.stringify(createTransport));
            }
        }
        else
            console.error("createTransport Unknown Type:", payload.type);
    };


    /**
     * Create WebRTC Transport for Incoming Data + Media
     * @param action
     * @param payload
     * @param call_state
     * @returns {Promise<boolean>}
     */
    async updateSignallingServer(action, payload, call_state, rid = null) {

        console.log('About to send %s action to fanout [%s,%s,%s]', action, call_state, fanoutId);
        let request = 'unknown';

        let data = {
            request,
            fanoutId,
            name: call_state && call_state.username ? call_state.username : '',
            routerId: call_state && call_state.routerId ? call_state.routerId : null,
            sessionId: call_state && call_state.sessionId ? call_state.sessionId : null,
            uid: call_state && call_state.uid ? call_state.uid : null,
            alias: call_state && call_state.conferenceAlias ?
                call_state.conferenceAlias : (call_state.eventId ?
                    call_state.eventId : (call_state.alias ?
                        call_state.alias : '')),
            payload,
            rid: rid || uuidv1(),
        }

        switch (action) {
            case 'create-room':
                data.request = Defines.Fanout.Signalling.CreateRoom;
                break;
            case 'join-room':
                data.request = Defines.Fanout.Signalling.JoinRoom;
                break;
            case 'join-conference':
                data.request = Defines.Fanout.Signalling.JoinConference;
                break;
            case 'create-transport':
                data.request = Defines.Fanout.Signalling.Transport.Create;
                break;
            case 'connect-transport':
                data.request = Defines.Fanout.Signalling.Transport.Connect;
                break;
            case 'consume':
                data.request = Defines.Fanout.Signalling.Media.Consume;
                break;
            case 'consume-resume':
                data.request = Defines.Fanout.Signalling.Media.ConsumeResume;
                break;
            case 'data-consume':
                data.request = Defines.Fanout.Signalling.DataChannel.Consume;
                break;
            case 'data-consume-ack':
                data.request = Defines.Fanout.Signalling.DataChannel.ConsumeAck;
                break;
            case 'produce':
                data.request = Defines.Fanout.Signalling.Media.Produce;
                break;
            case 'data-produce':
                data.request = Defines.Fanout.Signalling.DataChannel.Produce;
                break;
            case 'stop':
                data.request = Defines.Fanout.Signalling.Stop;
                break;
            case 'streaming':
                data.request = Defines.Fanout.Signalling.Streaming;
                break;
            case 'end-audience':
                data.request = Defines.Fanout.Signalling.EndForAudience;
                break;
            case 'chat-connect':
                data.request = Defines.Fanout.Chat.Connect;
                break;
            case 'chat-disconnect':
                data.request = Defines.Fanout.Chat.Disconnect;
                break;
            case 'chat-history':
                data.request = Defines.Fanout.Chat.History;
                break;
            case 'chat-message':
                data.request = Defines.Fanout.Chat.Message;
                break;
            case 'knock-request':
                data.request = Defines.Fanout.Knock.Request;
                break;
            case 'knock-requests':
                data.request = Defines.Fanout.Knock.Requests;
                break;
            case 'knock-granted':
                data.request = Defines.Fanout.Knock.Granted;
                break;
            case 'knock-rejected':
                data.request = Defines.Fanout.Knock.Rejected;
                break;
            case 'knock-revoked':
                data.request = Defines.Fanout.Knock.Revoked;
                break;
            case 'end-call':
                data.request = Defines.Fanout.Signalling.End;
                break;
            case 'verify-email':
                data.request = Defines.Fanout.Signalling.VerifyEmail;
                break;
            case 'ban-user':
                data.request = Defines.Fanout.Signalling.BanUser;
                break;
            case 'chat-delete-message':
                data.request = Defines.Fanout.Chat.DeleteMessage;
                break;
            case 'chat-ban-user-by-message':
                data.request = Defines.Fanout.Chat.BanUserByMessage
                break;
            case 'reconnect':
                data.request = Defines.Fanout.Signalling.Reconnect
                break;
            case 'reconnect-me':
                data.request = Defines.Fanout.Signalling.ReconnectMe
                break;
            case 'extend-call':
                data.request = Defines.Fanout.Signalling.Extend
                break;
            case 'get-ready':
                data.request = Defines.Fanout.Signalling.GetReady
                break;
            case 'at-ease':
                data.request = Defines.Fanout.Signalling.AtEase
                break;
            case 'cancelled':
                data.request = Defines.Fanout.Signalling.Cancelled
                break;
            case 'join':
                data.request = Defines.Fanout.Signalling.Join
                break;
            case 'change-layout':
                data.request = Defines.Fanout.Signalling.ChangeLayout
                break;
            default:
                break;
        }
        try {
            if (this._ws) {
                this._ws.send(JSON.stringify(data));
                console.log("FanoutSignalling::%s()   Sent object %o to WS", "update", data);
            }
            else
                console.warn("FanoutSignalling::%s()   No WS defined", ["update"]);
        } catch (e) {
            console.log('FanoutSignalling::%s()  Could not send ws data [%s]', ['update', e.messge]);
        }
        return Promise.resolve(true);
    };

    cleanTheRoomReconnect() {
        this._store.dispatch(knocksActions.clear());
    }

    deleteSignallingClientEntry(call_state) {
        const { conferenceAlias } = call_state;


        let data = {
            request: "delete-entry",
            alias: conferenceAlias,
            fanoutId
        };
        // this.sendToSignallingServerUpdate(data);
        try {
            if (this._ws) {
                this._ws.send(JSON.stringify(data));
                console.log("FanoutSignalling::%s()   Sent object %o to WS", "update", data);
            }
            else
                console.warn("FanoutSignalling::%s()   No WS defined", ["update"]);
        } catch (e) {
            console.log('FanoutSignalling::%s()  Could not send ws data [%s]', ['update', e.messge]);
        }
    }

    cleanTheRoom() {
        this._store.dispatch(knockActions.clear());
        this._store.dispatch(chatActions.clear());
        this._store.dispatch(audienceActions.clearList());
        this._store.dispatch(hostsActions.clearList());
        this._store.dispatch(bannedActions.clearList());
        this._store.dispatch(appActions.clear());
        this._store.dispatch(roomActions.clear());
    }

    checkOverrunsTimeout(eventItem, role, isOwner) {
        console.log('call checkOverrunsTimeout', eventItem, role);

        if (this.overrunsTimeout) {
            return;
        } else {
            if (eventItem && eventItem.startDate && eventItem.duration && role && ((role === 'presenter') || (role === 'moderator'))) {
                let now = Date.now(), creator = false;

                if (isOwner) {
                    creator = true;
                }

                let timeoutTimestamp = moment(new Date(eventItem.startDate ? eventItem.startDate : moment().unix())).add(parseInt(eventItem.duration) + 1, 'hours').subtract(creator ? 1 : 0, "minutes").valueOf() - now;

                if (timeoutTimestamp) {
                    this.overrunsTimeout = setTimeout(() => {
                        this.setCloseModalTimeout();
                        this._store.dispatch(roomActions.setModalEventOverrun({ modalEventOverrun: true }));
                    }, parseInt(timeoutTimestamp) || 0);
                }
            }
        }
    }

    sendExtendAgain(timestamp) {
        if (this.overrunsTimeout) {
            clearTimeout(this.overrunsTimeout);
        }

        this.overrunsTimeout = setTimeout(() => {
            this.setCloseModalTimeout();
            this._store.dispatch(roomActions.setModalEventOverrun({ modalEventOverrun: true }));
        }, parseInt(timestamp - Date.now()) || 0);
    }

    setCloseModalTimeout() {
        if (this.closeModalTimeout) {
            clearTimeout(this.closeModalTimeout);
        }

        this.closeModalTimeout = setTimeout(() => {
            this._store.dispatch(roomActions.setModalEventOverrun({ modalEventOverrun: false }));
            this._store.dispatch(roomActions.setGoingOut({ goingOut: true }));
        }, 5 * 60 * 1000);
    }

    // --- Other -------------------------------------------------------------------------------------------

    /**
     * Set Redux store
     * @param store
     */
    setStore(store) {
        this._store = store;
    }
    createMiddleware() {
        return ({ dispatch, getState }) => (next) => (action) => {
            // let state = getState();
            let res = next(action);
            switch (action.type) {
                default: { }
            }
            return res;
        };
    }
}
let fc = new FanoutClient();
window.fanoutClient = fc;
export default fc;