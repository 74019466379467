import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    preConfiguration: true,
    loading: true,
    lockJoin: false,
    audioInputDevice: null,
    audioOutputDevice: null,
    videoInputDevice: null,
    audioInputDevices: [],
    audioOutputDevices: [],
    videoInputDevices: [],
    userVideoStream: null,
    userAudioStream: null,
    error: null,
    videoEnabled: true,
    audioEnabled: true
}

export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        joinRoom: (state, action) => {
            return Object.assign({}, state, action.payload);
        },
        exitRoom: (state, action) => {
            return Object.assign({}, state, action.payload);
        },
        setDevicesData: (state, action) => {
            return Object.assign({}, state, action.payload);
        },
        setDevices: (state, action) => {
            return Object.assign({}, state, action.payload);
        },
        setVideoEnabled: (state, action) => {
            return Object.assign({}, state, { videoEnabled: action.payload });
        },
        setAudioEnabled: (state, action) => {
            return Object.assign({}, state, { audioEnabled: action.payload });
        },
        setAudioInputDevice: (state, action) => {
            return Object.assign({}, state, { audioInputDevice: action.payload });
        },
        setAudioOutputDevice: (state, action) => {
            return Object.assign({}, state, { audioOutputDevice: action.payload });
        },
        setVideoInputDevice: (state, action) => {
            return Object.assign({}, state, { videoInputDevice: action.payload });
        }
    },
});

const { actions, reducer } = deviceSlice;

// export individual action creator functions
export const {
    joinRoom,
    exitRoom,
    setDevices,
    setDevicesData,
    setVideoEnabled,
    setAudioEnabled,
    setAudioInputDevice,
    setAudioOutputDevice,
    setVideoInputDevice
} = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;