// Dev
const dev = {
    fanoutId: '34_69_53_183',
    messageDispatcherPort: 54331,
    messageDispatcherHost: "wss://conferencing.nofilterbroadcast.com",
    build: "DEV",
    homeSiteDomain: 'www.strmz.io',
    accountSiteDomain: 'account-dev.strmz.io',
    googleClientId: '104795671843-4dgpder6d9lrpj72sum0aroat257gr1f.apps.googleusercontent.com'
};
// Prod
const prod = {
    fanoutId: '34_136_113_219',
    messageDispatcherPort: 443,
    messageDispatcherHost: "wss://ws2.nofilter.net",
    build: "PROD",
    homeSiteDomain: 'www.strmz.io',
    accountSiteDomain: 'account.strmz.io',
    googleClientId: '104795671843-4dgpder6d9lrpj72sum0aroat257gr1f.apps.googleusercontent.com'
};

const config = process.env.NODE_ENV === 'development'
    ? dev
    : prod;


let configData = {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
}

export default configData;