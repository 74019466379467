import { createSlice } from '@reduxjs/toolkit';

const initialState = {
}

export const appSlice = createSlice({
    name: 'audience',
    initialState,
    reducers: {
        updateList: (state, action) => {
            if(action.payload) {
                // Remove it
                const next = { ...state };
                for (const [key, value] of Object.entries(action.payload)) {
                    // console.log(`${key}: ${value}`);
                    if(value)
                        next[key] = value;
                    else
                        delete next[key];
                }

                return next;
            }
            return state;
        },
        clearList: () => {
            return Object.assign({}, initialState);
        },
    },
});

const { actions, reducer } = appSlice;

// export individual action creator functions
export const {
    updateList,
    clearList
} = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;