import React, { Component } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../../utils/HelperFunctions';
import classNames from "classnames";
import fanoutClient from '../../../utils/FanoutClient';
import * as roomActions from "../../../reducers/room";
import KnockImg from "../../../resources/icons/room/hand.svg";

class KnockButtonBottomBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            knock: false,
            knockRid: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { knock } = this.state;
        const { knocks, getCallState, displayMessage } = this.props;

        if (JSON.stringify(knocks) && JSON.stringify(knocks) !== JSON.stringify(prevProps.knocks)) {
            if (Object.keys(knocks).length) {
                let callState = getCallState();
                Object.keys(knocks).map((key) => {
                    if (knocks[key] && callState && knocks[key].uid === callState.uid) {
                        this.setState({
                            check: false,
                            knock: true,
                            knockRid: knocks[key].rid
                        }, () => {
                            if (displayMessage) {
                                displayMessage('knock_on', 2000);
                            }
                        });
                    }
                    return key;
                });
            } else if (knock) {
                this.setState({
                    knock: false,
                    knockRid: null
                }, () => {
                    if (displayMessage) {
                        displayMessage('knock_off', 1000);
                    }
                });
            }
        }
    }

    handleKnock() {
        const { knock, knockRid } = this.state;
        const { getCallState } = this.props;

        if (!knock && getCallState) {
            this.setState({
                check: true
            }, () => {
                fanoutClient.sendKnockRequest(getCallState());
            });
        } else if (knock && knockRid && getCallState) {
            fanoutClient.sendKnockRevoked(knockRid, getCallState());
        }
    }

    render() {
        const { check, knock } = this.state;
        const { t, streaming } = this.props;

        return (
            <li className={classNames("toggle-knock", { 'active': knock }, { 'disable': check || (!streaming && !knock) })}>
                <a
                    data-tip
                    data-for="knock"
                    title={t('knock')}
                    onClick={this.handleKnock.bind(this)}
                >
                    <img src={KnockImg} alt='img' />
                    <div>
                        <span>{t('knock')}</span>
                    </div>
                </a>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        knocks: state.knocks,
        streaming: state.room.streaming
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        displayMessage: (message, timer) => {
            dispatch(roomActions.displayMessage({ message: message, timer: timer }));
        }
    };
};

const KnockContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(KnockButtonBottomBar);

export default withTranslation()(withRouter(KnockContainer));
