import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import userPlaceholder from '../resources/images/user-placeholder.png';

let today = new Date();
today.setDate(today.getDate() + 365);
const default_cookies_param = {
    path: "/",
    expires: today,
    secure: true,
    sameSite: 'none'
};

export function withRouter(Child) {
    return (props) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return (
            <Child
                {...props}
                navigate={navigate}
                location={location}
                params={params}
            />
        );
    }
}

export function numberFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}

export function toSeoUrl(url) {
    return url.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
        .replace(/\s+/g, '-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g, '-and-')          // Replace ampersand
        .replace(/[^a-z0-9-]/g, '')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g, '-')             // Remove duplicate dashes
        .replace(/^-*/, '')              // Remove starting dashes
        .replace(/-*$/, '');             // Remove trailing dashes
}

export function isIOS() {
    return (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
}

export function isMobile() {
    return isIOS() ||
        /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
}

export function validLink(text) {
    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(text)) {
        return false;
    } else {
        return true;
    }
}

export function getUserImg(user) {
    if (user) {
        if (user.profile_picture2) return user.profile_picture2;
        else if (user.profile_picture1) return user.profile_picture1;
        else if (user.profile_picture) return user.profile_picture;
        else return null;
    } else {
        return null;
    }
}

export function checkApprovedEntry(approvedEntry, eventItem) {
    let retValue = false;

    if (eventItem && eventItem.id && approvedEntry && eventItem.id === approvedEntry) {
        retValue = true;
    }

    return retValue;
}

export function parseSpeakerBanner(banner) {
    let newBanners = [];

    if (banner) {
        newBanners.push({
            id: uuidv4(),
            img: banner,
            link: null,
            default: true
        });
    }

    return newBanners;
}

export function getUserAvatar(user) {
    if (user) {
        if (user.profile_picture1) {
            return user.profile_picture1;
        } else if (user.profile_picture2) {
            return user.profile_picture2;
        } else if (user.profile_picture) {
            return user.profile_picture;
        } else return userPlaceholder;
    } else return userPlaceholder;
}

export function getTextParticipant(value) {
    let text = '1.000';

    switch (value) {
        case '1k':
        case '1000':
        case 1000:
            text = '1.000';
            break;
        case '2k':
        case '2000':
        case 2000:
            text = '2.000';
            break;
        case '3k':
        case '3000':
        case 3000:
            text = '3.000';
            break;
        case '5k':
        case '5000':
        case 5000:
            text = '5.000';
            break;
        case '10000':
        case 10000:
            text = '10.000';
            break;
        case '25000':
        case 25000:
            text = '25.000';
            break;
        case '50000':
        case 50000:
            text = '50.000';
            break;
        default:
            text = '50.000';
            break;
    }

    return text;
}

export function getTextDuration(value, t1, t2) {
    let text = `1 ${t1}`;

    switch (value) {
        case '1h':
        case '1':
        case 1:
            text = `1 ${t1}`;
            break;
        case '2h':
        case '2':
        case 2:
            text = `2 ${t2}`;
            break;
        case '3h':
        case '3':
        case 3:
            text = `3 ${t2}`;
            break;
        case '4h':
        case '4':
        case 4:
            text = `4 ${t2}`;
            break;
        case '5h':
        case '5':
        case 5:
            text = `5 ${t2}`;
            break;
        default:
            text = `5 ${t2}`;
            break;
    }

    return text;
}

export const dolbyStrings = {
    en: {
        noPstnNumbers: "There is no PSTN numbers...",
        electronloading: "Strmz.io is loading",
        error: "Error",
        errorPermissionDeniedMicrophone:
            "An error occured when joining the event. Please make sure to allow access to your microphone.",
        errorPermissionDeniedMicrophoneCamera:
            "An error occured when joining the event. Please make sure to allow access to your microphone and camera. Check if there’s another application using your camera and microphone",
        errorIE11:
            "A plugin is mandatory for IE11, please download and install the plugin. When the installation is complete, please refresh this page.",
        titleNotAllowedError: "Access to media devices not granted",
        descNotAllowedError:
            "When access to device is requested, you should see a one-time pop-up window requesting the user to grant access to media devices. You can use it to allow or forbid access to requested device. Please, refer to the browser-specific instructions for enabling access to the media devices.",
        titleOverconstrainedError: "Device does not match the hardware requirements of this application",
        descOverconstrainedError: "Please use use the settings menu to try to select another device.",
        titleNotFoundError: "Device with requested media not found",
        descNotFoundError: "Please check are all devices connected properly.",
        titleAbortError: "Device is not working",
        descAbortError: "Please check is device connected properly or use the settings menu to try to select another device.",
        titleNotReadableError: "Could not access the device",
        descNotReadableError: "Please check is any other application using the device  or use the settings menu to try to select another device.",
        titleSecurityError: "Media support is disabled",
        descSecurityError: "",
        titleTypeError: "Internal error",
        descTypeError: "Error has been logged. Sorry for inconvenience.",
        titleAlreadyStartedWarning: "Media is already started",
        descAlreadyStartedWarning: "",
        titleAlreadyStoppedWarning: "Media already stopped",
        descAlreadyStoppedWarning: "",
        titleNetworkConnectionError: "Network connection error",
        titleCallSetupError: "Call Setup Error",
        titleProgramError: "Program Error",
        titleSystemError: "System Error",
        titleConferenceCapacityError: "Unable to join the event.",
        descConferenceCapacityError: "Event is at the maximum capacity and is not accepting new participants.",
        descPeerConnectionFailedError: "Your computer cannot connect to the communications platform, check your network setup, and try again. If the problem persists, contact your app provider for help.",
        descPeerConnectionDisconnectedError: "There was a network connection error that may have affected the quality of your connection to the event. If the problem persists, try enabling Low bandwidth mode in your Settings.",
        descPeerNotFoundError: "Connection to server could not be established. Please check your network connection.",
        descRemoteDescriptionError: "The system was unable to set up your call due to a call negotiation failure. If you are using a mobile device, it might be a model that is not supported. Contact your app provider for help.",
        descCreateAnswerError: "The system was unable to set up your call due to a call negotiation failure. Contact your app provider for help.",
        descParticipantNotFoundError: "The system was unable to find the participant in your call. Contact your app provider for help.",
        titleDefaultError: "Failed accessing microphone or camera",
        descDefaultError: "Please check is device connected properly or use the settings menu to try to select another device.",
        browerNotSupported: "For an optimal viewing experience of strmz.io events on an Apple device, please use the Safari browser.",
        installExtension: "Please install the screen share extension with this ",
        noExtensionAvailable: "No Chrome Web Extension configure for screen share.",
        microphoneOff: "Your microphone is muted.",
        microphoneOn: "Your microphone is on.",
        cameraOn: "Your camera is on.",
        cameraOff: "Your camera is off.",
        recordConferenceStart: "Your event is being recorded.",
        recordConferenceStop: "Your event is no longer being recorded.",
        screenshareInProgress:
            "You cannot screenshare while another user is screensharing.",
        recordConferenceStartBy: "Your event is being recorded by ",
        recordConferenceStopBy: "Your event is no longer recorded by ",
        conferenceAlreadyRecord:
            "Your event is already being recorded by an other attendee.",
        leave: "End",
        audio: "3D Audio",
        audioTitle: "Audio",
        chat: "Chat",
        nameConversation: "Name this conversation",
        externalLive: "Live Broadcast",
        fullscreen: "FullScreen",
        minimize: "Minimize",
        mute: "Mute",
        pincode: "Call-in",
        sendMessage: "Send",
        pinCodeExplanations:
            "Call this number below and provide the event pin code to join the event via PSTN.",
        record: "Recording",
        shareAlreadyStarted:
            "Someone is already sharing. Please stop it before start a new one.",
        share: "Share",
        screenshare: "Share Screen",
        screenshareEntireScreen: "Share a Screen",
        screenshareAWindow: "Share a Window",
        screenshareOption: "Share Options",
        settings: "Settings",
        open: "Open",
        close: "Close",
        video: "Video",
        camera: "Camera",
        audioTransparentMode: "Audio transparency mode",
        liveCall: "Live is running",
        linkHls: "See your live",
        broadcastLive: "Diffusion en cours",
        externalUrl: "Configure your stream",
        externalPassword: "Enter your password from your live",
        launchLive: "Start your stream",
        stopLive: "Stop your live stream",
        geturl: "Get your stream url (on Youtube or Facebook)",
        getpwd: "Get your stream password (on Youtube or Facebook)",
        enterhere: "Enter your informations here :",
        titleSettings: "Set preferred camera and microphone",
        titleSettingsListenerOnly: "Set preferred output device",
        problemSettings: "If you are having problems, try restarting your browser.",
        saveSettings: "Your preferences will automatically save.",
        screensharerunning: "Happy Screen Sharing!",
        tile: "Tile",
        list: "List",
        speaker: "Speaker",
        displaymode: "Display mode",
        changelayout: "Change layout",
        attendees: "Attendees",
        participantsHeder: "Participants ",
        invitedHeder: "Invited",
        here: "here",
        hangtight: "We're waiting for other callers to arrive.",
        join: "Join",
        incall: "In call with :",
        joincall: "Join",
        expand: "Expand",
        activecall: "Active call",
        leavecall: "Leave call",
        output: "Output",
        input: "Input",
        titlePreConfig: "Set up your devices",
        addParticipant: "Add Participant",
        joined: "Joined",
        invited: "Waiting on",
        presenter: "Presenter",
        listener: "Listener",
        left: "Left",
        noAudioDevice:
            "No audio device detected. Please make sure to plug at least one microphone to access this event.",
        filepresentation: "Share a File",
        prev: "Previous",
        next: "Next",
        errorFilePresentation:
            "An error occured during the file presentation. Please check your file.",
        videopresentation: "Share a Video",
        placeholderVideoPresentation: "Video URL",
        startVideoPresentationAutoplay: "Start video",
        invitedUsers: "Waiting for invitation",
        inviteUser: "Invite",
        autoPlayBlocked: "The browser has blocked automatic audio playback due to a security policy, proceed to start hearing audio from the event.",
        autoPlayBlockedButton: "Play audio",
        lowBandwidthMode: "Low bandwidth mode",
        sendMyVideo: "Send my video",
        showVideoParticipants1: "Show video for up to",
        showVideoParticipants2: "participants",
        pinnedCameraOff: "Click icon to always view video from this participant",
        pinnedCameraOn: "Click icon to only view video from this participant if he/she talked",
        bokehMode: "Blur background",
        videoDenoise: "Video noise reduction",
        dolbyVoiceNotSupported: 'Unable to use Dolby Voice',
    }
};

export function checkVideoEnabled() {
    const videoEnabled = stringToBoolean(Cookies.get('videoEnabled'));

    if (!videoEnabled) {
        Cookies.set('videoEnabled', true, default_cookies_param);
    }
}

export function stringToBoolean(value) {
    try {
        switch (value) {
            case "true":
            case "yes":
            case "1":
                return true;

            case "false":
            case "no":
            case "0":
            case "":
            case null:
            case undefined:
                return false;

            default:
                return Boolean(value);
        }
    } catch (error) {
        return false;
    }
}

export const defaultColor = {
    eventPage: {
        bg: '#00000f'
    },
    eventTitle: {
        color: '#FFFFFF',
        bg: '#00000f'
    },
    eventDetails: {
        color: '#FFFFFF',
        bg: '#00000f'
    },
    eventRegistration: {
        color: '#FFFFFF',
        bg: '#00000f',
        btn: '#11b0ed'
    }
}

export function getParticipantsTerms(param) {
    let retVal = false;

    let storedTerms = JSON.parse(localStorage.getItem("NFBParticipantTerms"));

    if (storedTerms && storedTerms.length && Array.isArray(storedTerms) && param) {
        retVal = storedTerms.some(elem => elem && elem.param && elem.param === param);
    }

    return retVal;
}

export function setParticipantsTerms(param, value, startDate) {
    let checkTimestamp = moment(new Date()).subtract(5, 'hours').valueOf()
    let nowTimestamp = moment(new Date()).valueOf()

    let storedTerms = JSON.parse(localStorage.getItem("NFBParticipantTerms"));

    let filterTerms = storedTerms && Array.isArray(storedTerms) && storedTerms.length ? storedTerms.filter(elem => elem && elem.startDate && elem.startDate >= checkTimestamp) : [];

    const index = filterTerms.findIndex(elem => elem && elem.param && elem.param === param);

    if (index > -1) {
        if (!value) {
            filterTerms = filterTerms.filter(elem => elem && elem.param && elem.param !== param)
        }
    } else {
        if (value) {
            filterTerms.push({
                param: param,
                startDate: startDate ? startDate : nowTimestamp
            });
        }
    }

    localStorage.setItem("NFBParticipantTerms", JSON.stringify(filterTerms));
}

export function getReconnectToken(params) {
    let retVal = null;

    let tokenData = JSON.parse(localStorage.getItem("tokenData"));
    let nowTimestamp = moment(new Date()).valueOf()

    if (tokenData && tokenData.length && Array.isArray(tokenData) && params && params.email && params.eventId) {
        let tokenItem = tokenData.find(elem => elem && elem.email && elem.eventId && elem.email === params.email && elem.eventId === params.eventId && ((!elem.expiresAt) || (elem.expiresAt && elem.expiresAt > nowTimestamp)));
        retVal = tokenItem && tokenItem.reconnectToken ? tokenItem.reconnectToken : null;
    }

    return retVal;
}

export function setReconnectToken(params) {
    let checkTimestamp = moment(new Date()).subtract(5, 'hours').valueOf();

    let tokenData = JSON.parse(localStorage.getItem("tokenData"));

    let filterData = tokenData && Array.isArray(tokenData) && tokenData.length ? tokenData.filter(elem => elem && elem.startDate && elem.startDate >= checkTimestamp) : [];

    const index = filterData.findIndex(elem => elem && elem.email && elem.eventId && params.email && params.eventId && elem.email === params.email && elem.eventId === params.eventId);

    if (params && params.email && params.eventId && params.reconnectToken && params.startDate) {
        if (index !== -1) {
            filterData[index] = params;
        } else {
            filterData.push(params);
        }
    }

    localStorage.setItem("tokenData", JSON.stringify(filterData));
}

export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
}

export function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
        return `<a target="_blank" href="${url}">${url}</a>`;
    });
}

export function getOrientation(w, h) {
    let ar = w / h;
    return ar <= 1 ? 'vertical' : 'horizontal'
}

export function checkHost(email, eventItem) {
    let retVal = false;

    if (email && eventItem && eventItem.hosts && eventItem.hosts.length) {
        eventItem.hosts.map((item) => {
            if ((item.mail && item.mail === email) || (item.id && item.id === email)) {
                retVal = true;
            }
        });
    }

    return retVal;
}

export function checkModerator(email, eventItem) {
    let retVal = false;

    if (email && eventItem && eventItem.moderators && eventItem.moderators.length) {
        eventItem.moderators.map((item) => {
            if (item.id && item.id === email) {
                retVal = true;
            }
        });
    }

    return retVal;
}

export function checkGuestSpeaker(email, eventItem) {
    let retVal = false;

    if (email && eventItem && eventItem.guestSpeakers && eventItem.guestSpeakers.length) {
        eventItem.guestSpeakers.map((item) => {
            if (item.id && item.id === email) {
                retVal = true;
            }
        });
    }

    return retVal;
}

export function validatePassword(password) {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&*()_+\-=\[\]{};':"\\|,.<>])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>]{8,}$/;
    return re.test(String(password));
}