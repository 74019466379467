import React from 'react';
import classNames from "classnames";

function Loader(props) {
    return (
        <div className={classNames('lds-dual-ring', { 'appBg': props.bg })}>
            {props.text ?
                <p className='lds-text'>{props.text}{props.dots ? <span className='dot-flashing' /> : null}</p>
                : null
            }
        </div>
    )
}

export default Loader;