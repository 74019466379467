import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ClickOutside from '../Widget/ClickOutside';
import firebaseClient from '../../utils/FirebaseClient';
import { getUserAvatar } from '../../utils/HelperFunctions';
import config from '../../config';

function handleShowList(value, callback, menu) {
    if (value !== menu) {
        callback(value);
    }
}

function Profile() {
    const { t } = useTranslation();
    const user = useSelector(state => state.firebase.user);
    const [profileList, setProfileList] = useState(false);

    return (
        <ClickOutside
            className='header-profile'
            clickOutside={() => handleShowList(false, (value) => setProfileList(value), profileList)}
        >
            <img
                src={getUserAvatar(user)}
                onClick={() => setProfileList(!profileList)}
                alt='profile'
                loading='lazy'
            />
            {profileList ?
                <div className='profile-list'>
                    {user && user.eventAdmin ?
                        <button
                            className='btn btn-profile-list'
                            onClick={() => setProfileList(false)}
                        >
                            <a
                                href={`https://${config.accountSiteDomain}/my-profile`}
                                className="nav-link"
                            >
                                {t('my_profile')}
                            </a>
                        </button>
                        : null
                    }
                    {user && user.eventAdmin && user.subscription ?
                        <button
                            className='btn btn-profile-list'
                            onClick={() => setProfileList(false)}
                        >
                            <a
                                href={`https://${config.accountSiteDomain}/my-subscription`}
                                className="nav-link"
                            >
                                {t('my_subscription')}
                            </a>
                        </button>
                        : null
                    }
                    {user && user.eventAdmin && user.subscription ?
                        <button
                            className='btn btn-profile-list'
                            onClick={() => setProfileList(false)}
                        >
                            <a
                                href={`https://${config.accountSiteDomain}/my-hosts`}
                                className="nav-link"
                            >
                                {t('my_hosts')}
                            </a>
                        </button>
                        : null
                    }
                    <button
                        className='btn btn-profile-list'
                        onClick={() => firebaseClient.logOut()}
                    >
                        {t('logout')}
                    </button>
                </div>
                : null
            }
        </ClickOutside>
    )
}

export default Profile
